.container { max-width: 100%; }

:root {
  --clBlua:#3787E6 !important;
  --clBlub:#87BBF9 !important;
  --clBluc:#9BC3F2 !important;
  --clBlud:#C7DAF2 !important;
  --clBlue:#E6F1FF !important;
  --clBluf:#F7FAFF !important;
  --clBlug:#EEF0F4 !important;
  --clBluh:#F6F6F6 !important;
  --clBlka:#000000 !important;
  --clBlkf:#333333 !important;
  --clBlkb:#818181 !important;
  --clBlkc:#999999 !important;
  --clBlkd:#CBCBCB !important;
  --clBlke:#EDEDED !important;
  --clWhta:#FFFFFF !important;
  --clGrna:#4CD79E !important;
  --clReda:#FC0A0A !important;
  --clyloa:#EAAD04 !important;
}

.fs_9 { font-size: 9px !important; line-height: normal !important; }
.fs_10 { font-size: 10px !important; line-height: normal !important; }
.fs_11 { font-size: 11px !important; line-height: normal !important; }
.fs_12 { font-size: 12px !important; line-height: normal !important; }
.fs_13 { font-size: 13px !important; line-height: normal !important; }
.fs_14 { font-size: 14px !important; line-height: normal !important; }
.fs_15 { font-size: 15px !important; line-height: normal !important; }
.fs_16 { font-size: 16px !important; line-height: normal !important; }
.fs_17 { font-size: 17px !important; line-height: normal !important; }
.fs_18 { font-size: 18px !important; line-height: normal !important; }
.fs_19 { font-size: 19px !important; line-height: normal !important; }
.fs_20 { font-size: 20px !important; line-height: normal !important; }
.fs_21 { font-size: 21px !important; line-height: normal !important; }
.fs_22 { font-size: 22px !important; line-height: normal !important; }
.fs_23 { font-size: 23px !important; line-height: normal !important; }
.fs_24 { font-size: 24px !important; line-height: normal !important; }
.fs_26 { font-size: 26px !important; line-height: normal !important; }
.fs_28 { font-size: 28px !important; line-height: normal !important; }
.fs_30 { font-size: 30px !important; line-height: normal !important; }
.fs_32 { font-size: 32px !important; line-height: normal !important; }
.fs_32 { font-size: 32px !important; line-height: normal !important; }
.fs_34 { font-size: 34px !important; line-height: normal !important; }
.fs_36 { font-size: 36px !important; line-height: normal !important; }
.fs_36 { font-size: 36px !important; line-height: normal !important; }
.fs_38 { font-size: 38px !important; line-height: normal !important; }
.fs_40 { font-size: 40px !important; line-height: normal !important; }


@media screen and (max-width: 1023px) 
{
  .fs_9 { font-size: 7px !important; line-height: normal !important; }
  .fs_10 { font-size: 8px !important; line-height: normal !important; }
  .fs_11 { font-size: 9px !important; line-height: normal !important; }
  .fs_12 { font-size: 8px !important; line-height: 12px !important; }
  .fs_13 { font-size: 11px !important; line-height: normal !important; }
  .fs_14 { font-size: 12px !important; line-height: normal !important; }
  .fs_15 { font-size: 13px !important; line-height: normal !important; }
  .fs_16 { font-size: 14px !important; line-height: normal !important; }
  .fs_17 { font-size: 15px !important; line-height: normal !important; }
  .fs_18 { font-size: 16px !important; line-height: normal !important; }
  .fs_19 { font-size: 17px !important; line-height: normal !important; }
  .fs_20 { font-size: 18px !important; line-height: normal !important; }
  .fs_21 { font-size: 19px !important; line-height: normal !important; }
  .fs_22 { font-size: 20px !important; line-height: normal !important; }
  .fs_23 { font-size: 21px !important; line-height: normal !important; }
  .fs_24 { font-size: 22px !important; line-height: normal !important; }
  .fs_26 { font-size: 23px !important; line-height: normal !important; }
  .fs_28 { font-size: 24px !important; line-height: normal !important; }
  .fs_30 { font-size: 25px !important; line-height: normal !important; }
  .fs_32 { font-size: 26px !important; line-height: normal !important; }
  .fs_32 { font-size: 27px !important; line-height: normal !important; }
  .fs_34 { font-size: 28px !important; line-height: normal !important; }
  .fs_36 { font-size: 29px !important; line-height: normal !important; }
  .fs_36 { font-size: 30px !important; line-height: normal !important; }
  .fs_38 { font-size: 31px !important; line-height: normal !important; }
  .fs_40 { font-size: 32px !important; line-height: normal !important; }
}


@media screen and (min-width: 1024px) and ( max-width: 1560px) {
  /* .fs_9 { font-size: 7px !important; line-height: normal !important; }
  .fs_10 { font-size: 8px !important; line-height: normal !important; }
  .fs_11 { font-size: 9px !important; line-height: normal !important; }
  .fs_12 { font-size: 8px !important; line-height: 12px !important; }
  .fs_13 { font-size: 11px !important; line-height: normal !important; }
  .fs_14 { font-size: 12px !important; line-height: normal !important; }
  .fs_15 { font-size: 13px !important; line-height: normal !important; } */
  .fs_16 { font-size: 14px !important; line-height: normal !important; }
  .fs_17 { font-size: 15px !important; line-height: normal !important; }
  .fs_18 { font-size: 16px !important; line-height: normal !important; }
  .fs_19 { font-size: 17px !important; line-height: normal !important; }
  .fs_20 { font-size: 18px !important; line-height: normal !important; }
  .fs_21 { font-size: 19px !important; line-height: normal !important; }
  .fs_22 { font-size: 20px !important; line-height: normal !important; }
  .fs_23 { font-size: 21px !important; line-height: normal !important; }
  .fs_24 { font-size: 22px !important; line-height: normal !important; }
  .fs_26 { font-size: 23px !important; line-height: normal !important; }
  .fs_28 { font-size: 24px !important; line-height: normal !important; }
  .fs_30 { font-size: 25px !important; line-height: normal !important; }
  .fs_32 { font-size: 26px !important; line-height: normal !important; }
  .fs_32 { font-size: 27px !important; line-height: normal !important; }
  .fs_34 { font-size: 28px !important; line-height: normal !important; }
  .fs_36 { font-size: 29px !important; line-height: normal !important; }
  .fs_36 { font-size: 30px !important; line-height: normal !important; }
  .fs_38 { font-size: 31px !important; line-height: normal !important; }
  .fs_40 { font-size: 32px !important; line-height: normal !important; }
}

.lh_16 { line-height: 16px !important }
.lh_18 { line-height: 18px !important }
.lh_20 { line-height: 20px !important }
.lh_22 { line-height: 22px !important }
.lh_24 { line-height: 24px !important }
@media screen and (max-width: 1023px) {

.lh_16 { line-height: 16px !important }
.lh_18 { line-height: 18px !important }
.lh_20 { line-height: 20px !important }
.lh_22 { line-height: 22px !important }
.lh_24 { line-height: 24px !important }

}

.fsl_3 { font-weight: 300 !important }
.fsn_4 { font-weight: 400 !important; }
.fsm_5 { font-weight: 500 !important; font-weight: medium }
.fsb_6 { font-weight: 600 !important }
.fsb_7 { font-weight: 700 !important }

.txt_w { color: var(--clWhta) !important }
.txt_b { color: var(--clBlka) !important }
.txt_bf { color: var(--clBlkf) !important }
.c_black { color: #000000;}
.txt_p { color: var(--clBlua) !important }
.txt_s { color: var(--clGrna) !important }
.txt_d { color: var(--clReda) !important }
.text_red { color: #F00C0C !important; }
.txt_y { color: var(--clyloa)!important }
.txt_lb { color: var(--clBlkb)!important }
.txt_llb { color: #333333 !important; }
.txt_db { color: var(--clBlkc)!important }
.txt_g { color: #00000080!important }
.txt_cap { text-transform: capitalize }

.bg_w { background: var(--clWhta)!important }
.bg_b { background: var(--clBlka)!important }
.bg_p { background: var(--clBlua) }
.bg_s { background: var(--clGrna)!important }
.bg_d { background: var(--clReda)!important }
.bg_lb { background: var(--clBlkb)!important }
.bg_y { background: var(--clyloa)!important }

.br_4 { border-radius: 4px!important }
.br_6 { border-radius: 6px!important }
.br_8 { border-radius: 8px!important }
.ope_0 { opacity: 0 }
.ope_2 { opacity: 20% }
.ope_4 { opacity: 40% }
.ope_5 { opacity: 50% }
.ope_6 { opacity: 60% }
.ope_7 { opacity: 70% }
.ope_8 { opacity: 80% }

.b_none { border: none }

.ic_blue { filter: invert(10%) sepia(100%) saturate(7000%) hue-rotate(3787deg) brightness(102%) contrast(143%); }

.mb_24 { margin-bottom: 24px }
.mb_32 { margin-bottom: 32px }
.mt_24 { margin-top: 24px }
.mt_32 { margin-top: 32px }
.ml_24 { margin-left: 24px }
.mr_5 { margin-right: 5px; }
.mr_10 { margin-right: 10px; }
.ml_5 { margin-left: 5px; }
.ml_10 { margin-left: 10px; }

/* common position class */
.p_reletive { position: relative;}
.P_absolute { position: absolute;}
.mat-mdc-form-field-wrapper { padding-bottom: 0px;  }
.mat-mdc-form-field-infix {  border-top: 0px; }
.carriercommunityplan .mat-mdc-form-field-wrapper { display: flex; align-items: center; height: 37px;}
.carriercommunityplan .mat-mdc-form-field-infix  {display: inline-flex !important; align-items: center;}
.create_load_tabs .mat-mdc-tab-nav-bar, .mat-mdc-tab-header { border-bottom: none; }
.create_load_tabs .mat-mdc-ink-bar  { display: none;} 



.b_r_0 { border-top-right-radius: 0!important; border-bottom-right-radius: 0!important }
.bor_n { border: none }
.boxS_0 { box-shadow: none!important }
.help_pointer { cursor: help }
.pointer { cursor: pointer }
.txt_break { word-break: break-all }

.mat-mdc-radio-group { border: none }
.input_solid_border { border: 1px solid #D9D9D9; display: flex; align-items: center; }

input[type=file],input[type=file]::-webkit-file-upload-button { cursor:pointer }
.mat-mdc-select-placeholder,
input::placeholder { text-transform: capitalize; font-size: 12px; font-weight: 500; color: var(--clBlkb); }

// .input_field_box #custom_input_2 .mat-mdc-form-field-infix { padding-top: 0px; padding-bottom: 0px; }
#custom_input_2 .mat-mdc-form-field-infix { min-height: 40px; }
#custom_input_2 .mat-mdc-text-field-wrapper { margin-top: 10px;}
.mat-mdc-form-field-infix {
  min-height: auto;
}
.mdc-text-field { padding: inherit !important; }
.mdc-line-ripple { display: none; }


.card .card-header-danger .card-icon,
.card .card-header-danger .card-text,
.card .card-header-danger:not(.card-header-icon):not(.card-header-text),
.card.bg-danger,
.card.card-rotate.bg-danger .back,
.card.card-rotate.bg-danger .front { background:linear-gradient(60deg,var(--clBlua),var(--clBlua)) }

.srch_top { width:100%; max-width:175px }
.srch_top .mat-mdc-form-field-underline { display:none }
.srch_top .mat-mdc-form-field-wrapper { padding-bottom: 0px; }
.srch_top .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {background-color: var(--clWhta); display: flex; padding: 8px 10px; align-items: center; border-radius: 5px 0px 0px 5px; min-height: 29px; }
.driver_search_input .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex { border: 1px solid #d9d9d9; }
.driver_search .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex { padding: 0px 0px 0 11px !important; min-height: 30px !important; border-radius: 5px; border: 1px solid #d9d9d9; margin-left: 10px;}


.srch_top .mat-mdc-form-field-prefix { border:2; }
.srch_top .mat-mdc-icon { width:1.714rem; cursor:pointer; margin:0 8px 0 0 }
.srch_top .material-icons { color:var(--clBlub) }
.srch_top .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-infix { padding: 0px!important; font-size:1rem; line-height:.143rem; border-top:0; width: 95%; }
.srch_top .mat-mdc-form-field-infix .mat-mdc-input-element::placeholder { font-size:12px; font-weight:400; color:var(--clBlkb); }
.srch_top.network_src .mat-mdc-form-field .mat-mdc-form-field-infix { padding: 4.5px; border-top:0 }
.srch_top.network_src .mat-mdc-form-field-infix .mat-mdc-input-element::placeholder { color:var(--clBlkb) }
.l_srch_top .mat-mdc-form-field .mat-mdc-form-field-flex { background-color:var(--clWhta); border-radius:4px; padding:0 8px 0 0; display:flex; align-items:center }
.l_srch_top .mat-mdc-form-field .mat-mdc-form-field-infix { padding:0; font-size:1rem; line-height:.143rem; border-top:0 }


@media screen and (max-width:1400px) {

  .srch_top .mat-mdc-form-field-infix .mat-mdc-input-element::placeholder { font-size:10px; }
  
}


.vertical_tab { display:flex; flex-direction:row }
.tab_list { display:flex; flex-basis:19%; flex-direction:column }
.tabcontent { margin:0 0 0 2rem; flex-basis:84%; border-left:none; position:relative; height:calc(100vh - 166px) }
.tab_list .list-group-item { font-size:.857rem; line-height:1rem; font-weight:500; background-color:inherit; color:var(--clBlka); padding:.286rem 0 .286rem .857rem; margin:.286rem 0; width:100%; cursor:pointer }
.tab_list .list-group-item:hover { color:var(--clBlka) }
.tab_list .list-group-item.active { background-color:transparent; margin:.286rem 0 }
.tab_list .list-group-item-action:focus,
.tab_list .list-group-item-action:hover { background-color:#f8f9fa00 }
.tab_list .list-group-item.active { color:var(--clBlua); font-weight:600 }
.tab_list .list-group-item.active:before { content:""; width:.357rem; height:1.071rem; border-radius:6px; background:var(--clBlua); position:absolute; left:0; top:.143rem }

.laLogo2x { width:34px; height:34px; background:url(https://staticprod.laneaxis.com/portal/images/after-login/LA-fav.png); background-attachment:inherit; background-repeat:no-repeat; background-position:center; background-size:contain }
#upload_file { width:21px; height:21px; background:url(https://staticprod.laneaxis.com/portal/images/after-login/portal-icons.svg) -16px -15px }
#download_file { width:21px; height:21px; background:url(https://staticprod.laneaxis.com/portal/images/after-login/portal-icons.svg) -35px -5px }

.disa { opacity:50%; box-shadow:none; cursor:no-drop!important }
.disa:hover { opacity:50%; box-shadow:none; cursor:no-drop!important }

.btn { text-transform:inherit; box-shadow:none; }
.btn:disabled { opacity:50%; box-shadow:none; cursor: not-allowed; pointer-events: auto; }

.disabled,
.disabled:hover { opacity:50%; cursor:no-drop; box-shadow:none }
.disabledbt { opacity:50%; box-shadow:none; cursor:no-drop!important }

.button_primary:disabled:hover { cursor: no-drop; background: #fff; box-shadow: none; color: #818181; }
.button_primary,
.button_primary:active,
.button_primary:focus,
.button_primary:hover { color:var(--clWhta); background:var(--clBlua); box-shadow:none; box-shadow:0 5px 12px 0 #041b3533; }

.custum_button { font-size:14px; font-weight:400; line-height:20px; text-transform:inherit; min-width:9.429rem; max-width:100%; border-radius:30px; padding:10px 18px; box-shadow:none; cursor:pointer }

.button_success:disabled:hover { cursor:no-drop; background:var(--clGrna); box-shadow:none }
.button_success,
.button_success:active,
.button_success:focus,
.button_success:hover { color:var(--clWhta); background:var(--clGrna); box-shadow:none }

.button_danger,
.button_danger:active,
.button_danger:focus,
.button_danger:hover { color:var(--clWhta); background:var(--clReda); box-shadow:none }

.custum_input_css .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper { margin:0 }
.custum_input_css .mat-mdc-form-field-wrapper { padding:0 0 20px 0 }
.update_profile_con .custum_input_css .mat-mdc-form-field-wrapper { padding:0 0 10px 0 }
.custum_input_css .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-subscript-wrapper { padding:0 0 0 .2rem; font-size:.714rem }
.src_cancel_icons .mat-mdc-form-field-subscript-wrapper { padding-top: 0px; padding-bottom: 0px; }
.mat-mdc-form-field-subscript-wrapper { display: none;}
.custum_input_css .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix { padding:12px 0 5px 0 }
// .custum_input_css .mat-mdc-form-field-infix { border-top:.286rem solid transparent }
.custum_input_css .upload_dock .mat-mdc-form-field-wrapper { padding:0 }
.custum_input_css .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float .mat-mdc-input-server:focus+.mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label,
.custum_input_css .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label { transform:translateY(-.929rem) scale(.75)!important }
.custum_input_css .mat-mdc-form-field:not(.mat-mdc-form-field-appearance-legacy) .mat-mdc-form-field-suffix .mat-mdc-icon-button { background:var(--clBluh); width:35px; height:40px; border-radius:0 4px 4px 0 }
.custum_input_css .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline,
.custum_input_css .select_date .mat-mdc-form-field:not(.mat-mdc-form-field-appearance-legacy) .mat-mdc-form-field-prefix .mat-mdc-datepicker-toggle-default-icon { color:#d7e9ff }
.custum_input_css .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick { color:#d7e9ff }
.custum_input_css .mat-mdc-input-element { color:var(--clBlka)!important; caret-color:var(--clBlka); font-size: 12px!important; font-weight:500; height:40px; background:var(--clBluh); padding:0 1rem }
.custum_input_css .mat-mdc-form-field-label { color:#3787e6db; font-size:.857rem!important }
.custum_input_css .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-start { border-radius:2px!important }
.custum_input_css .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-end { border-radius:2px!important }
.custum_input_css .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-start { border:2px solid currentColor!important; border-right:none!important }
.custum_input_css .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-gap { border:2px solid currentColor; border-left-style:none; border-right-style:none }
.custum_input_css .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-end { border:2px solid currentColor!important; border-left:none!important }
.custum_input_css .mat-mdc-form-field-infix { border-top:0 solid transparent }
.custum_input_css .mat-mdc-form-field-label-wrapper { top:-.643rem }
.custum_input_css .drop_bg .mat-mdc-form-field-flex .mat-mdc-form-field-outline { background:#e6f1ff }
.custum_input_css .drop_bg .mat-mdc-form-field-flex .mat-mdc-form-field-label { color:#3787e6eb }
.custum_input_css .mat-mdc-select-value { color:#000; font-size:.857rem }
.custum_input_css .mat-mdc-form-field-appearance-outline .mat-mdc-select-arrow-wrapper { transform:translateY(0)!important }
.custum_input_css .mat-mdc-select-arrow { color:var(--clBlua); margin:-.357rem .286rem; border-left:.429rem solid transparent; border-right:.429rem solid transparent; border-top:.429rem solid; position:relative; right:-.429rem }
.custum_input_css .select_time .mat-mdc-form-field-appearance-outline .mat-mdc-select-arrow-wrapper { transform:translate(4px,-3px)!important }
.custum_input_css .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-label { top:1.84375em; margin-top:.071rem }
.custum_input_css .mat-mdc-select-trigger { height:1.214em }
.custum_input_css .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-subscript-wrapper .mat-mdc-error { color:var(--clReda) }
.custum_input_css .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-invalid.mat-mdc-form-field-invalid .mat-mdc-form-field-outline-thick { color:var(--clReda) }
.custum_input_css .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,.custum_input_css .mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label { color:var(--clReda) }
.custum_input_css .custom_suffix_txt.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-suffix { top:-.2em }
.custom_inpt_pb_2 .mat-mdc-form-field-wrapper { padding:0 0 2px 0 }
textarea.custom_input { height: auto !important }
.password_icon .mat-mdc-form-field:not(.mat-mdc-form-field-appearance-legacy) .mat-mdc-form-field-prefix .mat-mdc-icon-button,
.password_icon .mat-mdc-form-field:not(.mat-mdc-form-field-appearance-legacy) .mat-mdc-form-field-suffix .mat-mdc-icon-button { display:block; font-size:inherit; width:1.929rem; height:1.929rem; position:absolute; bottom:-.206rem; right:0!important; top:5px!important }

.mat-mdc-input-element::placeholder { color: var(--clBlkb); font-weight: 500; }

.feedback_input .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline { display:none!important }

.shipment_by_lane .mat-mdc-radio-container { background:#f0f0f0!important; border-radius:50% }
.mat-mdc-radio-inner-circle { background-color:#3787e6!important }
/* .shipment_by_lane .mat-mdc-radio-outer-circle { border:none!important } */
.shipment_by_lane .mat-mdc-radio-group { display:flex; justify-content:space-between; min-width:450px }

.custom_redio_btn .mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle,
.custom_redio_btn .mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-ripple .mat-mdc-ripple-element:not(.mat-mdc-radio-persistent-ripple),
.custom_redio_btn .mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-persistent-ripple,
.custom_redio_btn .mat-mdc-radio-button.mat-mdc-accent:active .mat-mdc-radio-persistent-ripple { background-color:var(--clBlua) !important; }
.custom_redio_btn .mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle { border-color:#d9d9d9 !important; }
.custom_redio_btn .mat-mdc-radio-button .mat-mdc-radio-label { margin:0 }
.custom_redio_btn .mat-mdc-radio-outer-circle { border-color:#d9d9d9 !important; }

.custom_checkbox .mat-mdc-checkbox-frame { border-width:1px }
.custom_checkbox .mat-mdc-checkbox-inner-container { height:16px; margin-right:8px; width:16px }
.custom_checkbox .mat-mdc-checkbox-frame { border-color:#82828280; width:16px; height:16px }
.custom_checkbox .mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background,
.custom_checkbox .mat-mdc-checkbox-indeterminate.mat-mdc-accent .mat-mdc-checkbox-background { background-color:var(--clBlua) }
.custom_checkbox .mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-mdc-accent .mat-mdc-ripple-element,
.custom_checkbox .mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-mdc-accent .mat-mdc-ripple-element { background:var(--clBlua) }
.custom_checkbox .mat-mdc-checkbox-layout .mat-mdc-checkbox-label { line-height:20px; color:var(--clBlkb) }
.custom_checkbox .mat-mdc-checkbox .mat-mdc-checkbox-ripple { display:none }

.tab_container_inpput .mat-mdc-form-field-wrapper { padding-bottom:0!important }
.tab_container_inpput .mat-mdc-form-field-infix { padding:12px 0!important; border-top:0!important }
.tab_container_inpput .resend_otp_space .mat-mdc-form-field-infix { padding:12px 0 0!important }


/* map css */
.map_shadow { margin-top:-60px; display:inline-block; width:100% }
#map_shadow_top { position:relative }
#map_shadow_top::after { content:''; position:absolute; z-index:1; background:-moz-linear-gradient(top,#e5f0fe 0,#e5f0fefc 1%,#e5f0fef2 5%,#7db9e800 100%); background:-webkit-linear-gradient(top,#e5f0fe 0,#e5f0fefc 1%,#e5f0fef2 5%,#7db9e800 100%); background:linear-gradient(to bottom,#e5f0fe 0,#e5f0fefc 1%,#e5f0fef2 5%,#7db9e800 100%); width:100%; height:80px; left:0; top:0 }
.driver_view_detail #map_shadow_top::after { z-index:2 }
#map { position:relative; z-index:1; width:100%; height:calc(100vh - 15vh); margin-top:70px }
.network_conr #map { position:relative; z-index:1; width:100%; height:calc(100vh - 15vh); margin-top:0 }
.pickupActive { color:var(--clWhta)!important; background:var(--clBlua)!important }
.dropActive { color:var(--clWhta)!important; background:var(--clBlua)!important }
.routeActive { color:var(--clWhta)!important; background:var(--clBlua)!important }
.card_map_overlay #map { position:relative; z-index:2; width:100%; height:600px; margin:0 0 25px 0 }

::scrollbar { width: 4px; height: 4px; }
::scrollbar-track { background: #f1f1f1; }
::scrollbar-thumb { background: #888; }
::scrollbar-thumb:hover { background: #555; }

::-webkit-scrollbar { width: 4px; height: 4px; }
::-webkit-scrollbar-track { background: #f1f1f1; }
::-webkit-scrollbar-thumb { background: #888; }
::-webkit-scrollbar-thumb:hover { background: #555; }

::-moz-scrollbar { width: 4px; height: 4px; }
::-moz-scrollbar-track { background: #f1f1f1; }
::-moz-scrollbar-thumb { background: #888; }
::-moz-scrollbar-thumb:hover { background: #555; }

::-o-scrollbar { width: 4px; height: 4px; }
::-o-scrollbar-track { background: #f1f1f1; }
::-o-scrollbar-thumb { background: #888; }
::-o-scrollbar-thumb:hover { background: #555; }

.hidden_scrollBar::scrollbar { width: 0; background: 0 0 }
.hidden_scrollBar::scrollbar-thumb { box-shadow: none; background: 0 0 }
.hidden_scrollBar::-webkit-scrollbar { width: 0; background: 0 0 }
.hidden_scrollBar::-webkit-scrollbar-thumb { box-shadow: none; background: 0 0 }
.hidden_scrollBar { scrollbar-color: #cfcece00 #cfcece00; scrollbar-width: none }

.mat-mdc-select-panel { scrollbar-width: thin; border-radius: 4px }
.mat-mdc-select-panel::scrollbar { width: 6px; height: 6px; background-color: #ecf1f700 }
.mat-mdc-select-panel::scrollbar-thumb { border-radius: 4px; box-shadow: inset 0 0 6px #0000001a; background-color: #d7d6d6 }
.mat-mdc-select-panel::-webkit-scrollbar { width:6px; height:6px; background-color:#ecf1f700 }
.mat-mdc-select-panel::-webkit-scrollbar-thumb { border-radius: 4px; -webkit-box-shadow: inset 0 0 6px #0000001a; background-color: #d7d6d6 }

.v_scrollBar { overflow-y:auto; overflow-x:hidden; scrollbar-width:thin; border-radius:4px }
.list_scroll { height:calc(100vh - 160px); overflow-y:auto; overflow-x:hidden; scrollbar-width:thin; border-radius:4px }
.list_scroll::-webkit-scrollbar-track { background: #fff; }
.list_scroll::-webkit-scrollbar-thumb { background: #d9d9d9; border-radius: 10px; }
.list_scroll::-webkit-scrollbar { width: 4px; height: 2px; }
.man_carrier_scroll { height:calc(100vh - 185px); overflow-y:auto; overflow-x:hidden }

.custom_dropdown { position:relative }
.custom_dropdown .dropdown-menu.show { position:absolute; will-change:top,left,bottom,right; right:0; left:auto!important; min-width:3vw; float:right; top:30px!important; border:none }
.custom_dropdown .dropdown-menu { padding:6px 0; margin:0; box-shadow:0 6px 22px -4px rgb(0 0 0 / 15%); border-radius:4px }
.custom_dropdown .dropdown-menu .dropdown-item { padding: 5px 0; font-size:1rem; line-height:18px; font-weight:500; color:var(--clBlkb); min-width:10rem; margin:0 7px 0 14px; font-display:swap; }
.custom_dropdown .dropdown-menu .dropdown-item:focus,
.custom_dropdown .dropdown-menu .dropdown-item:hover { box-shadow:none; background-color:transparent!important; color:var(--clBlka)!important }
.custom_dropdown .dropdown-menu a:active,
.custom_dropdown .dropdown-menu a:focus,
.custom_dropdown .dropdown-menu a:hover { box-shadow:none; background-color:transparent!important; color:var(--clBlua)!important }

.dropdown_menu_list .dropdown-menu .dropdown-item:hover { color:var(--clBlka)!important; background:inherit!important; box-shadow:none!important }
.dropdown_menu_list .dropdown-menu .dropdown-item.disabled_drop_btn { background:0 0; color:transparent!important }
.dropdown_menu_list .dropdown-menu .dropdown-item.disabled_drop_btn:hover { cursor:no-drop; color:transparent!important }

.backTO { height:16px; margin:0 0 0 -5px; display:flex; align-items:center; cursor:pointer }
.back_link { font-size:.857rem; font-weight:500; color:var(--clBlua)!important; margin:0 0 0 -.357rem; cursor:pointer; display:flex }
.back_link i { font-size:1.286rem; padding:0 .143rem .143rem 0 }

.view-doc-bg .mat-mdc-dialog-container { box-shadow:none; background:#fff0; color:#000000de;  padding:24px 15px; }
.cdk-overlay-dark-backdrop { background:#000000b8 }

.data_not_found { padding:2rem 0 1.071rem 0; border-radius:6px }
.data_not_found_head { text-align:center; margin:0 0 2rem }

.doc_success_tag:before { left:2.5rem; content:""; position:absolute; width:0; height:0; box-sizing:border-box; transform-origin:0 0; transform:rotate(90deg); border-radius:0 0 0 4px; border:.714rem solid #9a9a9a; border-color:transparent transparent var(--clGrna) var(--clGrna) }
.doc_danger_tag:before { left:2.5rem; content:""; position:absolute; width:0; height:0; box-sizing:border-box; transform-origin:0 0; transform:rotate(90deg); border-radius:0 0 0 4px; border:.714rem solid #9a9a9a; border-color:transparent transparent var(--clReda) var(--clReda) }

.popup_activity { margin:2.5rem 0 0 0 }
.popup_activity .header_mess { margin:0 0 3.929rem 0 }
.popup_activity div span { text-decoration:underline }

.cdk-overlay-backdrop.g-transparent-backdrop.cdk-overlay-backdrop-showing { background:var(--clBlka); opacity:75% }
.cdk-overlay-backdrop.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing { background:var(--clBlka); opacity:0 }
.mat-mdc-dialog-container { box-shadow:0 0 10px 0 #c0dbff; border-radius:10px!important }
.cdk-overlay-container .cdk-overlay-pane.view-doc-bg { max-width:100vw!important }
.info_header_left { flex-basis:16%; align-items:center; display:flex }
.view_head_right .q1,.view_head_right .q2 { flex-basis:50% }
.view_head_right { flex-basis:92%; margin:0; display:flex; align-items:center }
.info_header_right { flex-basis:84%; display:flex; align-items:center; margin:0 0 0 2rem }
.info_header_right .q1 { flex-basis:50%; display:flex; align-items:center }
.info_header_right .q2 { flex-basis:50%; display:flex; align-items:center; justify-content:flex-end }

.custom_badge { background:red; position:absolute; top:10px; left:19px; padding:2px 3px; color:var(--clWhta) }
.card .card-header { z-index:2!important }
.text_truncate { white-space:nowrap; overflow:hidden; text-overflow:ellipsis }
.H_l_right .H_l_anchor.H_l_horizontal { display:none }
.dri_stats { font-size:.857rem; padding:3px 6px; border-radius:4px; color:var(--clWhta) }
.stats_available { background:var(--clGrna) }
.stats_invited { background:#88178800; border:1px solid var(--clGrna); border-radius:4px; padding:2px 10px; color:var(--clGrna) }
.stats_inTransit { background:var(--clBlua) }
.stats_suspend { background:var(--clReda) }
.hide_input .mat-mdc-form-field-flex { display:none }

.custom_matSuffix_icon .mat-mdc-form-field-suffix { position:absolute; right:5px; top:17px }
.custom_matSuffix_icon .mat-mdc-button-focus-overlay,
.custom_matSuffix_icon .mat-mdc-button-ripple.mat-mdc-ripple { display:none }
.custom_matSuffix_icon .mat-mdc-form-field-suffix { position:absolute; right:10px; top:16px }

.pac-container.pac-logo:after { display:none!important }
.pac-item { border-top: 1px solid var(--clBlke); font-size: 11px !important; color: var(--clBlkb)!important; font-weight: 400; padding: 4px 8px }
.pac-item:hover { background-color:#f9f9f9 }
.pac-item-query { font-size:11px!important; color:var(--clBlkb) }

.network_sugBox .pac-container { margin: 13px 0 0 -25px; width: auto !important; max-width: 500px !important; }
.userProfile_sugBox .pac-container { position:fixed!important }
.onBoarding_sugBox .pac-container { margin:5px 0 0 0; position:fixed!important }
.dark_bg_skton .loader { background:0 0/200px 100% no-repeat #3f8ce74a!important; background-image:linear-gradient(90deg,rgba(255,255,255,0),rgba(255,255,255,.6),rgba(255,255,255,0))!important }

.loader_m_0 .loader { margin:0!important }
.loader_mb_5 .loader { margin:0 0 5px!important }
.loader_mb_0 .loader { margin-bottom:0!important }
.loader_mt_0 .loader { margin-top:0!important }
.loader_mt_5 .loader { margin-top:5px!important }
.loader_mt_10 .loader { margin-top:10px!important }
.loader_mb_10 .loader { margin-bottom:10px!important }
.loader_m_0 .loader.circle { margin:0!important }
.skeleton_mb_2 .loader { margin-bottom:2px!important }
.w_bg_skton .loader { background-image:linear-gradient(90deg,#f7faff,#fffffff0,#f7faff)!important }

.message_overview .mat-mdc-tab-header::after { position:absolute; width:100%; height:2px; content:''; background:#c7daf200; bottom:0; z-index:0 }
.message_overview .mat-mdc-tab-header { background:#fff0; border:none }
.message_overview .mat-mdc-tab-body-content { overflow: hidden; }
/* .message_overview .mat-mdc-tab-body-wrapper { margin:-30px 0 0; z-index:0 } */
.message_overview .v_scrollBar { border-radius: 0px; }
/* .message_overview .mat-mdc-ink-bar::after { position:absolute; content:''; height:2px; width:158px; background:var(--clWhta); bottom:-56px } */
/* .message_overview .mat-mdc-ink-bar { position:absolute; height:2px; transition:none; background:var(--clBlua)!important; max-width:160px!important; width:auto } */

.mat-mdc-ripple-element { background:#e6f1ff52 }

.vertical_step_wizard .mat-mdc-form-field-wrapper { padding-bottom:.5em }
.unread_badge .mat-mdc-badge-medium .mat-mdc-badge-content { width:16px; height:16px; line-height:15px; font-size:10px; font-weight:400; top:-18px!important; left:-12px!important; background:var(--clReda) }

.mat-mdc-dialog-container::-webkit-scrollbar { width:6px; background-color:#f4f9ff }
.mat-mdc-dialog-container::-webkit-scrollbar-thumb { border-radius:2px; -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.1); background-color:#afcef3; text-align:center; padding:0 10px }
.selection_src { background-image:none!important; width:98% }
#mapNetwork { width:100%; height:100% }

.selection_tab .mat-mdc-tab-header { background:#fff0; border:none }
.selection_tab .mat-mdc-ink-bar::after { position:absolute; content:''; height:2px; width:158px; background:var(--clWhta); bottom:-56px }
.selection_tab .mat-mdc-ink-bar { position:absolute; height:2px; transition:none; background:var(--clBlua)!important }

.custum_input_css .shipper_reg .mat-mdc-form-field-subscript-wrapper { margin:.5rem 0 0 }
.circle_check .mat-mdc-checkbox-frame { border-radius:50% }
.circle_check .mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background,
.circle_check .mat-mdc-checkbox-indeterminate.mat-mdc-accent .mat-mdc-checkbox-background { background-color:var(--clGrna); border-radius:50% }
.circle_check .mat-mdc-checkbox-inner-container { height:32px; width:32px }
.circle_check .mat-mdc-checkbox-frame { border-width:1px }
.circle_check .mat-mdc-checkbox-frame { border-color:#d8d8d8 }
.circle_check .mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-mdc-accent .mat-mdc-ripple-element,
.circle_check .mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-mdc-accent .mat-mdc-ripple-element { background:var(--clGrna) }
.circle_check .mat-mdc-checkbox-checked .mat-mdc-checkbox-checkmark { padding:7px }
.circle_check .mat-mdc-checkbox-layout { margin:0 }

.cirCheck_22 .mat-mdc-checkbox-inner-container { height:22px; width:22px }
.cirCheck_22 .mat-mdc-checkbox-checked .mat-mdc-checkbox-checkmark { padding:4px }

.card_inpt input::placeholder { color:var(--clBlka)!important }
.mat-mdc-form-field { display:inline!important }
.shipment_list_body .custom_input { padding: 10px !important; font-size: 12px !important; line-height: normal !important;  border: 1px solid #d9d9d9!important; border-radius: 5px 0px 0px 5px !important; background: #fff !important;} 
.shipment_list_body .custom_input::placeholder { font-size: 12px ; line-height: normal; } 
.custom_input { font-size:14px!important; line-height:18px!important; font-weight:500!important; color:var(--clBlka)!important; width:100%!important; padding:11px 16px!important; border:none!important; border-radius:4px!important; background:var(--clBluh)!important; box-sizing:border-box!important; height: 40px; }
.custom_input:focus { outline:0!important; box-shadow:none!important; background:var(--clBluh)!important }
.custom_input::placeholder { font-size: 12px!important; font-weight: 500!important; color: var(--clBlkb)!important; line-height: 16px!important }
.custom_input .mat-mdc-select-value-text { font-size:14px; font-weight:500; color:var(--clBlka) }

.ic_dropdown mat-mdc-select { background-image:none!important }
.ic_dropdown .mat-mdc-select-arrow-wrapper:before { content: ''; background: url('https://staticprod.laneaxis.com/portal/icon/ic_dropdown_gray.svg'); width: 12px; height: 8px; background-repeat: no-repeat; display: inline-block; }
.notification_body .ic_dropdown .mat-mdc-select-arrow-wrapper:before { width: 18px; height: 11px; background: url('https://staticprod.laneaxis.com/portal/icon/ic_dropdown_gray_2x.svg'); background-repeat: no-repeat !important; }
.notification_body .mat-mdc-tab-header { margin: 0 30px; }
.ic_dropdown.bg_none .mat-mdc-select-arrow-wrapper:before {background: none;}

@media screen and (max-width:767px) {
  
  .notification_body .mat-mdc-tab-header { margin: 0 15px; }
}
.ic_dropdownQ .mat-mdc-select-arrow-wrapper:before { font-size: 23px !important; font-weight: 600 !important;}
.ic_dropdown .mat-mdc-select-arrow { display:none }

.ic_calander .mat-mdc-button-wrapper:before { content: ''; display: block; background: url(https://staticprod.laneaxis.com/portal/icon/ic_calendar.svg); width: 18px; height: 18px; background-repeat: no-repeat; }
.ic_calander .mat-mdc-form-field-underline { display:none }

.lg_rgcal .mat-mdc-button-wrapper:before { margin:-7px 0 0 0!important; padding:0!important }
.lg_rgOnbrd .mat-mdc-button-wrapper:before { margin:-3px 0 0 0!important; padding:0!important }

.ic_calander .mat-mdc-form-field:not(.mat-mdc-form-field-appearance-legacy) .mat-mdc-form-field-prefix .mat-mdc-icon-button,
.ic_calander .mat-mdc-form-field:not(.mat-mdc-form-field-appearance-legacy) .mat-mdc-form-field-suffix .mat-mdc-icon-button { right:0; top:-.5px }
.ic_calander .mat-mdc-icon-button .mat-mdc-button-wrapper svg { display:none!important }
.ic_calander .mat-mdc-button-focus-overlay,
.ic_calander .mat-mdc-button-ripple.mat-mdc-ripple { display:none }
.ic_calander .mat-mdc-form-field-flex { display:flex; align-items:center }
.ic_calander .custom_input { border-radius:4px 0 0 4px!important }

// .ic_password .mat-mdc-icon-button { margin:-5px 0 0 }
.ic_password .mat-mdc-select-arrow-wrapper:before { content:"\e900"; font-display:swap; font-family:icomoon; font-size:16px; color:var(--clBlkb) }

.cu_loca_map #map { position:relative!important; margin:0!important; height:calc(100vh - 65px)!important }

.dots_menu { font-size:24px; border-radius:4px; cursor:pointer }
.dots_menu:hover { background:#d8d8d8 }

.cu_loca_map .marker { max-width:20px; max-height:48px }
.cu_loca_map .marker::before { position:absolute; content:""; background-image:url(https://staticprod.laneaxis.com/portal/images/after-login/map-icons/map-pin-network.png); width:53px; height:53px; z-index:-1; top:-5px; left:-16px }
.cu_loca_map .no_profile_marker { border-radius:50%; font-size:12px!important; font-weight:600; letter-spacing:.5px; max-width:22px; max-height:22px; background-color:var(--clBlua); position:absolute; z-index:0; color:var(--clBlka); cursor:pointer; display:flex; align-items:center; justify-content:center; text-transform:uppercase }
.marker img { width:28px; height:28px; display:flex; position:absolute; top:-4px; left:-5px; border-radius:50%; cursor:pointer }
.marker.active_profile { box-shadow:0 0 24px 12px var(--clBlka); max-height:20px; max-width:20px; z-index:1 }
.detail_mapbox .marker { display:flex; align-items:center; justify-content:center; border-radius:50%; font-size:12px!important; font-weight:600; letter-spacing:1px; max-width:20px; max-height:20px; background-color:var(--clBlua); position:absolute; z-index:0; color:var(--clBlka); text-transform:uppercase }
.src_cancel_icons .mat-mdc-form-field-suffix { display:flex; align-items:center }
.mapOn_shipView #map { height:500px!important }
.mapOn_shipView #map .mapboxgl-canvas { width:100%!important; height:100%!important }
.empty_state { padding:0; background:var(--clWhta); border-radius:4px }

.mat_dropdown.mat-mdc-option .mat-mdc-option-text { font-size:14px; font-weight:400; color:var(--clBlkb) }
.mat_dropdown.mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) { background:#3787e612 }
.mat_dropdown.mat-mdc-option:focus,.mat_dropdown.mat-mdc-option:hover { background:rgb(241 247 253 / 50%) }
.mat-mdc-select-panel { box-shadow:0 4px 12px 0 rgb(0 0 0 / 16%)!important; border-radius:4px!important }

.ng-trigger.ng-trigger-transformPanel.mat-mdc-select-panel::-webkit-scrollbar { width:.571rem!important; height:.571rem!important; background-color:#ecf1f700!important }
.ng-trigger.ng-trigger-transformPanel.mat-mdc-select-panel::-webkit-scrollbar-thumb { border-radius:4px!important; box-shadow:inset 0 0 6px rgba(0,0,0,.1)!important; background-color:#f4f9ff!important }
.ng-trigger.ng-trigger-transformPanel.mat-mdc-select-panel::-moz-scrollbar { -moz-width:.571rem!important; -moz-height:.571rem!important; -moz-background-color:#ecf1f700!important }
.ng-trigger.ng-trigger-transformPanel.mat-mdc-select-panel::-moz-scrollbar-thumb { -moz-border-radius:4px!important; -moz-box-shadow:inset 0 0 6px rgba(0,0,0,.1)!important; -moz-background-color:#f4f9ff!important }

[type=button],
[type=reset],
[type=submit] { -webkit-appearance:button; -moz-appearance:inherit }

.newtast1 p { margin:0 0 0; color:var(--clBlkb); font-weight:400 }
.Empty_State_body { width:auto; background:var(--clWhta); border-radius:5px; display:flex; justify-content:center; align-items:center; padding:5rem 0; box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15); }
.Empty_State_body_withou_box { width:auto; background:var(--clWhta); border-radius:4px; display:flex; justify-content:center; align-items:center; padding:5rem 0; margin:0 16px 22px; border-radius:6px }
.Empty_State_con { text-align:center; margin:0 auto 0; max-width:55% }
.Empty_State_img { max-width:250px; margin:0 auto }

@media (max-width:460px) {
    .Empty_State_con { max-width:100% }
}

.newtast1 p span { color:var(--clBlkb)!important; font-weight:400!important }
.list_option { font-size:30px; line-height:30px; margin:0 0 0 1rem; cursor:pointer; border-radius:4px }
.list_option:hover { background:#d8d8d8 }

.notification_icon { display:flex; justify-content:center; align-items:center; margin:0 6px 0 0; color:var(--clWhta); border-radius:50%; padding:2px; max-width:40px; max-height:40px; width:100% }
.notification_icon:before { font-display:swap; font-family:icomoon; width:40px; height:40px; font-size:24px; padding:8px; border-radius:50%; display:flex; align-items:center }
.success { background:var(--clGrna)!important }
.warning { background:var(--clyloa)!important }
.danger { background:var(--clReda)!important }

.spinner>div { width:6px; height:6px; margin-right:2px; background-color:#333; border-radius:100%; display:inline-block; -webkit-animation:sk-bouncedelay 1.4s infinite ease-in-out both; animation:sk-bouncedelay 1.4s infinite ease-in-out both }
.spinner .bounce1 { -webkit-animation-delay:-.32s; animation-delay:-.32s }
.spinner .bounce2 { -webkit-animation-delay:-.16s; animation-delay:-.16s }

@-webkit-keyframes sk-bouncedelay {
    0%,100%,80% { -webkit-transform:scale(0) }
    40% { -webkit-transform:scale(1) }
}
@keyframes sk-bouncedelay {
    0%,100%,80% { -webkit-transform:scale(0); transform:scale(0) }
    40% { -webkit-transform:scale(1); transform:scale(1) }
}
.custom_toggle .mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-thumb { background-color:var(--clBlua) }
.custom_toggle .mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-bar { background-color:hsl(213 78% 56% / 0.54) }
.custom_toggle.clr_tgl .mat-mdc-slide-toggle .mat-mdc-slide-toggle-thumb { background-color:var(--clBlua) }
.custom_toggle.clr_tgl .mat-mdc-slide-toggle .mat-mdc-slide-toggle-bar { background-color:hsl(213 78% 56% / 0.54) }

.tab_header .mat-mdc-tab-header { border-bottom:none }
.tab_header .mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar { background-color:var(--clBlua) }
.mat-mdc-tooltip { font-size:16px!important; font-weight:500!important }

.alert .alert_action { padding:2px 0 2px 22px; position:initial!important; display:block; cursor:pointer }
.alert span { display:block; max-width:92%; word-break:break-word }
.alert.alert-with-icon .close.mat-mdc-button { border:none; min-width:auto; width:24px; height:24px; padding:0 }
.alert.alert-with-icon .close i { position:initial; left:auto; top:auto; margin:auto }
.alert.alert-with-icon i { font-size:30px; display:block; left:15px; position:absolute; top:50%; margin-top:-15px; color:#fff }
.advance_src .srch_top .mat-mdc-form-field-wrapper { padding-bottom:0 }
.advance_src .mat-mdc-form-field-prefix { border:inherit }

.disabled_txt { opacity:.5 }
.disabled_txt:hover { cursor:no-drop }

.upload_document .mat-mdc-form-field-infix { display:flex; align-items:center }
.upload_document .custom_input { border-radius:2px 0 0 2px }
.profile_container .card_containerr image { opacity:.25!important }

.strength_slider .mat-mdc-slider-horizontal { width:100%; padding:0 0; height:8px }
.strength_slider .mat-mdc-slider-horizontal .mat-mdc-slider-wrapper { height:6px; top:0; left:0; right:0; border-radius:4px; overflow:hidden }
.strength_slider .mat-mdc-slider-horizontal .mat-mdc-slider-track-wrapper { height:6px }
.strength_slider .mat-mdc-slider-horizontal .mat-mdc-slider-track-background { height:6px }
.strength_slider .mat-mdc-slider-horizontal .mat-mdc-slider-track-fill { height:6px; border-radius:4px }
.strength_slider .mat-mdc-slider-track-background { background-color:#f1f1f1 }
.strength_slider:hover .mat-mdc-slider-track-background { background-color:#f1f1f1 }
.strength_slider .mat-mdc-slider-horizontal .mat-mdc-slider-ticks-container { height:6px }
.strength_slider .mat-mdc-slider-horizontal .mat-mdc-slider-ticks { height:6px }
.strength_slider .mat-mdc-slider-disabled .mat-mdc-slider-thumb { border-width:0; transform:initial; right:-10px; bottom:-3px; box-sizing:border-box; width:20px; height:6px; border:none; border-radius:0 4px 4px 0 }
.strength_slider.low_profile_strenth .mat-mdc-accent .mat-mdc-slider-thumb,
.strength_slider.low_profile_strenth .mat-mdc-accent .mat-mdc-slider-thumb-label,
.strength_slider.low_profile_strenth .mat-mdc-accent .mat-mdc-slider-track-fill { background-color:var(--clReda) }
.strength_slider.medium_profile_strenth .mat-mdc-accent .mat-mdc-slider-thumb,
.strength_slider.medium_profile_strenth .mat-mdc-accent .mat-mdc-slider-thumb-label,
.strength_slider.medium_profile_strenth .mat-mdc-accent .mat-mdc-slider-track-fill { background-color:#ff0 }
.strength_slider.high_profile_strenth .mat-mdc-accent .mat-mdc-slider-thumb,
.strength_slider.high_profile_strenth .mat-mdc-accent .mat-mdc-slider-thumb-label,
.strength_slider.high_profile_strenth .mat-mdc-accent .mat-mdc-slider-track-fill { background-color:var(--clGrna) }

.inviteDriver_pop .mat-mdc-tab-body-content { height:100%; overflow:inherit }

.cus_toggle.mat-mdc-button-toggle-group,
.cus_toggle.mat-mdc-button-toggle-standalone { border-radius:5px; width:100%; box-shadow:none; border:1px solid #e6e6e6; height:44px; align-items:center; padding:4px; font-size:14px; font-weight:600 }
.cus_toggle .mat-mdc-button-toggle { white-space:nowrap; position:relative; width:50% }
.cus_toggle .mat-mdc-button-toggle-checked { background-color:var(--clBlua); border-radius:5px; color:var(--clWhta)!important }
.cus_toggle .mat-mdc-button-toggle { color:var(--clBlkb) }

.mat-mdc-tab-label-active {  background: #3787e6 !important; border: none !important; }
.mat-mdc-tab-label-active .mat-mdc-tab-label-content { color: #fff !important; font-weight: 500;}

.radio_color { background-image:none!important }
.radio_color .mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle,
.radio_color .mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-ripple .mat-mdc-ripple-element:not(.mat-mdc-radio-persistent-ripple),
.radio_color .mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-persistent-ripple,
.radio_color .mat-mdc-radio-button.mat-mdc-accent:active .mat-mdc-radio-persistent-ripple { background-color:var(--clBlua) }
.radio_color .mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle { border-color:var(--clBlua) }

.Sacramento_txt { font-display:swap; font-family:Sacramento; color:var(--clBlka); font-weight:400 }
.GreatVibes_txt { font-display:swap; font-family:'Great Vibes'; color:var(--clBlka); font-weight:400 }
.Yellowtail_txt { font-display:swap; font-family:Yellowtail; color:var(--clBlka); font-weight:400 }
.Tangerine_txt { font-display:swap; font-family:Tangerine; color:var(--clBlka); font-weight:400 }
.Cedarville_txt { font-display:swap; font-family:Cedarville; color:var(--clBlka); font-weight:400 }

.DancingScript_txt { font-display:swap; font-family:'Dancing Script'; color:var(--clBlka); font-weight:400 }
.checked_radio .mat-mdc-radio-outer-circle { border-color:#cecece }
.checked_radio .mat-mdc-radio-label-content { color:var(--clBlkb) }
.checked_radio .mat-mdc-radio-outer-circle { border-width:1px }
.checked_radio .material-icons { display:none }
.checked_radio .mat-mdc-radio-checked .mat-mdc-radio-label-content { position:relative }
.checked_radio .mat-mdc-radio-checked .mat-mdc-radio-label-content .material-icons { display:inherit; position:absolute; left:-22px; top:-3px; color:#03a3e1; background:var(--clWhta); border-radius:50% }

.onboarding .mat-mdc-form-field-subscript-wrapper { margin:0 }
.mat-mdc-radio-checked .mat-mdc-radio-label-content { font-weight: 500;}
.cus_matTab .mat-mdc-tab-header { border:none; }
.cus_matTab .mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar { background-color:var(--clBlua) }
.cus_matTab .mat-mdc-tab-body-content { overflow:hidden }
.content_loader { text-align:center; margin:15px 0 5px }
.loader_image img { animation:contentLaderRotation 2s infinite linear }
@keyframes contentLaderRotation {
  from { transform:rotate(0) }
  to { transform:rotate(359deg) }
}

.network_bg_map canvas.mapboxgl-canvas { height:100%!important; width:100%!important; min-height:100% }
.network_bg_map #map { height:100%; width:100%; margin:0 }
.show-section { display:block }
.hide-section { display:none }
.map_cards_container .long_card_scroll { min-height:155px; max-height:calc(100vh - 155px); height:auto; margin:0 }
/* .sm_mapCard_scroll .tray_card_con { box-shadow:none!important; margin:0 0 10px 0!important } */
.map_sm_cardDetail .side_contaier { max-width:inherit!important; background:0 0!important; position:inherit!important }
.map_sm_cardDetail .side_content { height:calc(100vh - 245px)!important }
.map_sm_cards .side_content { height:calc(100vh - 200px)!important }
.map_sm_cards .side_contaier { left: 15px; }
.error_mess { color:var(--clReda); font-size: 10px; line-height: 14px; font-weight:500 }

.card_container .mat-mdc-stepper-horizontal { background:#0000 }
.card_container .mat-mdc-horizontal-stepper-header { display:flex; height:44px; overflow:hidden; align-items:center; padding:0 24px; width:33%; justify-content:center }
.card_container .mat-mdc-stepper-horizontal-line { border-top-width:1px; border-top-style:solid; flex:auto; height:0; margin:0 -16px; min-width:32px; display:none }
.card_container .mat-mdc-horizontal-stepper-header-container { background:var(--clWhta); border-radius:8px; box-shadow:0 8px 16px 0 #041a3433 }
.card_container .mat-mdc-horizontal-stepper-header.cdk-focused .mat-mdc-step-label-selected { color:var(--clWhta)!important }
.card_container .mat-mdc-step-label { font-size:16px; font-weight:500; color:var(--clBlkb)!important; display:flex; align-items:center }
.card_container .mat-mdc-step-label .mat-mdc-step-text-label { font-display:swap; display:flex; align-items:center }
.card_container .shipment mat-mdc-step-header:nth-child(1) { background:var(--clBlua); border-radius:8px; color:var(--clWhta)!important }
.card_container .shipment mat-mdc-step-header:nth-child(3) { background:var(--clWhta); border-radius:8px; color:var(--clBlkb)!important }
.card_container .shipment mat-mdc-step-header:nth-child(5) { background:var(--clWhta); border-radius:8px; color:var(--clBlkb)!important }
.card_container .freightRate mat-mdc-step-header:nth-child(1) { background:var(--clWhta); border-radius:8px; color:var(--clBlkb)!important }
.card_container .freightRate mat-mdc-step-header:nth-child(3) { background:var(--clBlua); border-radius:8px; color:var(--clWhta)!important }
.card_container .freightRate mat-mdc-step-header:nth-child(5) { background:var(--clWhta); border-radius:8px; color:var(--clBlkb)!important }
.card_container .selectedCarrier mat-mdc-step-header:nth-child(1) { background:var(--clWhta); border-radius:8px; color:var(--clBlkb)!important }
.card_container .selectedCarrier mat-mdc-step-header:nth-child(3) { background:var(--clWhta); border-radius:8px; color:var(--clBlkb)!important }
.card_container .selectedCarrier mat-mdc-step-header:nth-child(5) { background:var(--clBlua); border-radius:8px; color:var(--clWhta)!important }
.card_container .mat-mdc-step-label-selected { color:var(--clWhta)!important }
.card_container .mat-mdc-horizontal-content-container { overflow:inherit; padding:0 }
.crt_Ship_body .card_container .mat-mdc-horizontal-stepper-header { width:50% }

.rounded_h_tab .mat-mdc-horizontal-stepper-header-container { border-radius:40px; box-shadow:0 5px 10px #0000001a }
.rounded_h_tab .freightRate mat-mdc-step-header:nth-child(1),
.rounded_h_tab .freightRate mat-mdc-step-header:nth-child(3),
.rounded_h_tab .freightRate mat-mdc-step-header:nth-child(5),
.rounded_h_tab .selectedCarrier mat-mdc-step-header:nth-child(1),
.rounded_h_tab .selectedCarrier mat-mdc-step-header:nth-child(3),
.rounded_h_tab .selectedCarrier mat-mdc-step-header:nth-child(5),
.rounded_h_tab .shipment mat-mdc-step-header:nth-child(1),
.rounded_h_tab .shipment mat-mdc-step-header:nth-child(3),
.rounded_h_tab .shipment mat-mdc-step-header:nth-child(5) { border-radius:40px }

.assign_carrier .circle_check .mat-mdc-checkbox-inner-container { height:24px; width:24px }
.assign_carrier .circle_check .mat-mdc-checkbox-checked .mat-mdc-checkbox-checkmark { padding:4px }

.pay_method .custom_redio_btn .mat-mdc-radio-label { flex-direction:row-reverse; min-width:300px; margin:0 3px; justify-content:space-between; padding:15px 12px; border:1px solid var(--clBlkd); border-radius:6px }
.pay_method .custom_redio_btn .mat-mdc-radio-checked .mat-mdc-radio-label { flex-direction:row-reverse; min-width:300px; background:#e7f1ff; margin:0 3px; justify-content:space-between; padding:15px 12px; border:1px solid var(--clBlub) }
.pay_method .custom_redio_btn .mat-mdc-radio-label-content { font-size:16px; font-weight:400; color:var(--clBlka) }
.pay_method .custom_redio_btn .mat-mdc-radio-outer-circle { border-color:var(--clBlkd) }
.pay_buy_lods .mat-mdc-radio-button { width:48% }

.payment_method .custom_redio_btn .mat-mdc-radio-label { padding:15px 12px; border:1px solid var(--clBlkd); border-radius:6px; display:block }
.payment_method .custom_redio_btn .mat-mdc-radio-checked .mat-mdc-radio-label { background:#e7f1ff; border:1px solid var(--clBlub); display:block }
.payment_method .mat-mdc-radio-checked .payment_selectionr { color:var(--clGrna) }
.payment_method .pay_text { padding-left:12px }
.payment_method .mat-mdc-radio-checked .pay_text { color:var(--clBlka) }
.payment_method .mat-mdc-radio-group .mat-mdc-radio-container { display:none }
.payment_method .mat-mdc-radio-label-content { display:block; padding:0 }
.payment_method .mat-mdc-radio-button .payment_selection { display:flex }
.payment_method .mat-mdc-radio-button .payment_selectionr { display:none }
.payment_method .mat-mdc-radio-button.mat-mdc-radio-checked .payment_selectionr { display:block; margin-left:18px }
.prefix_txt .mat-mdc-form-field-prefix { width:10%; height:2.85rem; padding:.9rem; border:none; border-radius:4px 0 0 4px; background:var(--clBluh) }

.crt_Ship_body .mat-mdc-horizontal-stepper-header .mat-mdc-step-icon { display:none }

.input_prefix .mat-mdc-form-field-flex { align-items:center }
.input_prefix .custom_input { border-radius:0 4px 4px 0!important }
.input_prefix .prefix_text { border-radius:4px 0 0 4px!important }
.input_suffix .mat-mdc-form-field-flex { align-items:center }
.input_suffix .custom_input { border-radius:4px 0 0 4px!important }
.input_suffix .suffix_text { border-radius:0 4px 4px 0!important }
.mat-mdc-checkbox-disabled,
.mat-mdc-select-disabled { cursor:no-drop!important; opacity:.5 }
.mat-mdc-select-disabled .mat-mdc-select-trigger { cursor:no-drop!important }
.shipmetList_con .inProgress .loca_icon,
.shipmetList_con .past .loca_icon { color:var(--clBlua) }
.shipmetList_con .inProgress .sc_loca_picup:before,
.shipmetList_con .past .sc_loca_picup:before { color:#3786e640 }
/* .loca_iconFlag .picup_blue { width:14px; margin:0 0 0 10px; filter:grayscale(100%); opacity:.4 } */
.shipmetList_con .inProgress .loca_iconFlag .picup_blue,
/* .shipmetList_con .past .loca_iconFlag .picup_blue { filter:grayscale(0); opacity:1 } */
/* .activeBidding .sc_locations,
.availableLoads .sc_locations,
.pastBidding .sc_locations { margin:16px -16px 0; padding:16px 16px 0; border-top:1px solid var(--clBlke) } */
.filtr_top_arrow .ic_calander .mat-mdc-form-field-suffix { display:flex; align-items:center; background:var(--clBluh) }
.filtr_top_arrow .ic_calander .clear_date { display:flex!important; align-items:center!important; height:40px; cursor:pointer; color:var(--clBlkb) }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity:1
}
.card_detail .mat-mdc-form-field-wrapper { padding:0 }
.card_detail .mat-mdc-radio-label-content { width:100%!important }

.usd_iconM { background-image:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-dollar-sign.svg); background-repeat:no-repeat; width:24px; height:24px; background-size:contain; display:inline-flex; margin:0 }
.usdc_iconM { background-image:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-currency-usdc.svg); background-repeat:no-repeat; width:24px; height:24px; background-size:contain; display:inline-flex; margin:0 5px 0 0 }
.usd_white_iconM { background-image:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-dollar-sign-white.svg); background-repeat:no-repeat; width:24px; height:24px; background-size:contain; display:inline-flex; margin:0 -4px 0 2px }
.usd_iconS { background-image:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-dollar-sign-blue-24.svg); background-repeat:no-repeat; width:8px; height:15px; background-size:contain; display:inline-block }
.awardedBids .usd_iconS { background-image:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-dollar-g.svg) }
.usdc_iconS { background-image:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-USDC-16.svg); background-repeat:no-repeat; width:16px; height:16px; background-size:contain; display:inline-block; margin:0 4px 0 0 }
.loadHistory_panel .line_before { margin:0 0 22px -16px }

#visa1x {width:42px; height:16px;background:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -2px -4px; }
#visa_line1x {width:36px; height:24px;background:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -4px -33px; }
#mastercard1x {width:36px; height:22px;background:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -4px 261px; }
#master_debit1x {width:36px; height:24px;background:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -4px 228px; }
#discover1x {width:36px; height:24px;background:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -4px 194px; }
#amex1x {width:44px; height:18px;background:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) 0 -175px; }
#diners1x {width:36px; height:26px;background:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -4px 128px; }
#jcb1x {width:36px; height:26px;background:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -4px 93px; }
#unionpay1x {width:36px; height:24px;background:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -3px 58px; }
#interac1x {width:24px; height:24px;background:url(https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/stripe-card-strip.png) -10px 24px; }

.hCard_skt .ske_t .ske_tl { width:15% }
.hCard_skt .ske_t .ske_tr { width: 82%; margin: 0 0 0 3%; }
.df_c { display:flex; align-items:center }

.shipmnt-side-panel .Empty_State_body { box-shadow:none; padding:2rem 0; margin:0 }
.shipmnt-side-panel .Empty_State_con { max-width:100%!important }
.shipmnt-side-panel .Empty_State_img { margin:0 auto 24px }

.initial_name { display:flex; align-items:center; justify-content:center; background:#3787e6; color:#fff; text-transform:uppercase }
.initial_c_32 { min-width:32px; max-width:32px; height:32px; border-radius:20px; font-size:14px; line-height:15px; font-weight:500 }
.initial_c_34 { min-width:34px; max-width:34px; height:34px; border-radius:20px; font-size:12px; line-height:12px; font-weight:500 }
.initial_c_40 { min-width:40px; max-width:40px; height:40px; border-radius:24px; font-size:18px; line-height:19px; font-weight:500 }
.initial_c_46 { min-width:46px; max-width:46px; height:46px; border-radius:24px; font-size:18px; line-height:19px; font-weight:500 }
.initial_c_64 { min-width:64px; max-width:64px; height:64px; border-radius:32px; font-size:26px; line-height:26px; font-weight:500 }
.initial_r_28 { min-width:28px; max-width:28px; height:28px; border-radius:4px; font-size:12px; line-height:12px; font-weight:500 }
.initial_r_30 { min-width:30px; max-width:30px; height:30px; border-radius:4px; font-size:14px; line-height:16px; font-weight:500 }
.initial_r_32 { min-width:32px; max-width:32px; height:32px; border-radius:4px; font-size:14px; line-height:16px; font-weight:500 }
.initial_r_38 { min-width:30px; max-width:30px; height:30px; border-radius: 50%; font-size:12px; line-height:16px; font-weight:500 }
.initial_r_40 { min-width:40px; max-width:40px; height:40px; border-radius:4px; font-size:18px; line-height:18px; font-weight:500 }
.initial_r_42 { min-width:42px; max-width:42px; height:42px; border-radius:4px; font-size:18px; line-height:18px; font-weight:500 }
.initial_r_46 { min-width:46px; max-width:46px; height:46px; border-radius:4px; font-size:24px; line-height:24px; font-weight:500 }
.initial_r_48 { min-width:48px; max-width:48px; height:48px; border-radius:6px; font-size:24px; line-height:24px; font-weight:500 }
.initial_r_44 { min-width:44px; max-width:44px; height:44px; border-radius:4px; font-size:18px; line-height:16px; font-weight:500 }
.initial_r_64 { min-width: 48px; max-width: 48px; height: 48px; border-radius:4px; font-size:18px; line-height:26px; font-weight:500 }
.selection_src .mat-mdc-select-search-clear { display:none!important }

.wn_shipment_expired { width:40px; height:40px; background:var(--clReda) url(https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/portal/images/shipment-map-icon/ic-shipment-expired.svg) no-repeat center center }
.wn_shipment_cancelled { width:40px; height:40px; background:var(--clReda) url(https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/portal/images/shipment-map-icon/ic-shipment-cancelled.svg) no-repeat center center; }
.wn_shipment_unavailable { width:40px; height:40px; background:var(--clReda) url(https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/portal/images/shipment-map-icon/ic-shipment-unavailable.svg) no-repeat center center;}
.wn_shipment_bid_lost { width:40px; height:40px; background:var(--clReda) url(https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/portal/images/shipment-map-icon/ic-shipment-bid-lost.svg) no-repeat center center; }
.cus_matTab .mat-mdc-tab-label-content { color: #333; font-size: 13px; font-style: normal; font-weight: 400; line-height: normal; height: 30px; opacity: 1; }

.carrrier_transaction_body .mat-mdc-tab-header { margin: 15px 30px 0 30px; padding: 5px 0px;}

@media screen and (max-width:1023px) {


  .cus_matTab .mat-mdc-tab-label-content { font-size: 12px;}
  .mat-mdc-tab-label { padding: 0 15px !important; }
  .wallet_pageBody .mat-mdc-tab-header { margin: 0 20px !important; }
  .wallet_header { margin: 0 20px !important; }
  
}

.wallet_body .mat-mdc-tab-header { margin: 0 30px;}

@media screen and (max-width:767px) {


  .wallet_body .mat-mdc-tab-label-container { overflow: auto; padding: 2px 0px; max-width: calc(344px - 10px); }
  .wallet_body .mat-mdc-tab-label-container::-webkit-scrollbar { height: 1px; }
  .carrrier_transaction_body .mat-mdc-tab-header { margin: 15px 20px 0 20px; }
  .chat_box_container { display: none; }
  .plr_30 { padding: 0px !important;}
  

}

.close_popup_Icon { display:flex; align-items:center; justify-content:flex-end;}
.close_Icon { font-size:18px; line-height:18px; font-weight:400; color:var(--clWhta); cursor:pointer; padding:2px; background:#0000001a; border-radius:6px; width:26px; height:26px; display:flex; align-items:center; justify-content:center; }
.close_Icon:hover { background:#00000080;}
.close_Icon_new:hover { cursor: pointer; }
.dropdown-menu { border:none; box-shadow:0 8px 16px 0 #00000026 }
.dropdown-menu .dropdown-item { padding: 5px 14px; width: auto; display: flex; flex-flow: nowrap; align-items: center; font-size: 13px; line-height: 15px; font-weight: 500; color: var(--clBlkb); }
.dropdown-menu .dropdown-item:hover { cursor: pointer; }
.prefix_input_align .mat-mdc-form-field-flex { display:flex; align-items:center }
.la_term .mat-mdc-radio-label { white-space:inherit }
.la_term .mat-mdc-radio-group { display:flex }
.feed_back_popup .spinner-border { display:inline-block; width:16px; height:16px; vertical-align:-.125em; border:2px solid currentColor; border-right-color:transparent; border-radius:50%; animation:.75s linear infinite spinner-border; margin:0 6px 0 0; color:#fff }
.upload_dock .btn,.upload_document .btn { border:none }
.upload_dock .custom_input { border-top-right-radius:0!important; border-bottom-right-radius:0!important }
.select_sorting .mat-mdc-select .mat-mdc-select-value { text-align:left }
.toggle_lable mat-mdc-button-toggle-group .mat-mdc-button-toggle-label-content { font-size:14px; line-height:26px; font-weight:500 }
.lable_checkbox .custom_checkbox .mat-mdc-checkbox-layout .mat-mdc-checkbox-label { color:var(--clBlka); font-size:14px; font-weight:500; }
.lable_toggle .mat-mdc-button-toggle-label-content { font-size:14px; line-height:26px; font-weight:500; }
.asl_icon .mat-mdc-form-field-suffix { position:absolute; right:6px; top:13px; }
.lo_list_icn { font-size:12px; line-height:12px; font-weight:500; color:#fff; padding:0 6px; border-radius:4px; margin:0 0 0 8px; border:1px solid transparent; }
.Yes.lo_list_icn { background:var(--clGrna); border:1px solid var(--clGrna); }
.No.lo_list_icn { background:0 0; border:1px solid #818181; color:#818181; }
mat-mdc-checkbox .mat-mdc-checkbox-layout { word-break:break-word; white-space:break-spaces; }
.order_list_con .mat-mdc-tab-body-content { padding:20px 0 0 }
.sub_tabs .mat-mdc-tab-body-wrapper { margin:-30px 0 0; z-index:0; }

.sub_tabs .mat-mdc-ink-bar { width:84px!important; }
.time_select_con .mat-mdc-radio-container { width:14px; height:14px; }
.time_select_con .mat-mdc-radio-outer-circle { height:14px; width:14px; }
.time_select_con .mat-mdc-radio-inner-circle { height:14px; width:14px; }
.time_select_con .mat-mdc-radio-button .mat-mdc-radio-ripple { left:calc(50% - 10px); top:calc(50% - 10px); height:20px; width:20px; }
.time_select_con .custom_input { padding:6px 12px 3px!important; }
.time_select_con mat-mdc-radio-button .mat-mdc-radio-label-content { color:#818181; font-weight:400; }
.time_select_con mat-mdc-radio-button.mat-mdc-radio-checked .mat-mdc-radio-label-content { color:#000; }

.picupDrop_Container .custum_input_css .mat-mdc-form-field-wrapper {  padding:0 0 .7rem 0; }
.picupDrop_Container .custum_input_css .mat-mdc-form-field-subscript-wrapper { margin-top:8px; }

.trmrtur_input .custom_input { padding-right:24px!important; }
.trmrtur_input .custom_input { padding-right:24px!important; }

.bdm_map .mapboxgl-popup-content { padding:12px!important; }
.bdm_map .map_marker { font-size:14px; line-height:18px; font-weight:500; color:#000; text-align:center; margin:0; opacity:.8; }
.bdm_map .detail_mapbox .marker img { max-width:20px; max-height:20px; position:initial; }
.bdm_map .detail_mapbox .mapboxgl-popup-close-button { display:none; }
.bdm_map .pop_hed { display:flex; }
.bdm_map .pop_hed .pop_hed_l { margin:0 8px 0 0; min-width:26px; }
.bdm_map .detail_mapbox .marker { max-width:24px; max-height:24px; }
.bdm_map .detail_mapbox .marker { padding:0; }
.bdm_map .pop_hed .pop_hed_titl { margin:0 0 5px; display:flex; align-items:center; min-height:24px; }
.bdm_map .pop_hed .pop_hed_titl span { font-display:swap; font-size:13px; font-weight:600; line-height:13px; }
.bdm_map .pop_hed .dat_tim { font-size:11px; line-height:14px; font-weight:500; color:#818181; margin:0 0 1px; }
.bdm_map .point_location { font-size:11px; line-height:13px; font-weight:500; color:#818181; margin:4px 0 0; display:flex; }
.bdm_map .mapboxgl-ctrl-bottom-left,.bdm_map .mapboxgl-ctrl-bottom-right { display:none; }
.driver_detail_con .shipment_card_T { padding:12px 10px 0!important; }
.driver_detail_con .shipment_card { margin:0 0 24px!important; }
/* .driver_detail_con .line_status_txt,.driver_detail_con .ship_id { font-size:14px!important; margin:0 0 10px!important; } */
.driver_detail_con .sc_center .edit_shipmentr { width:20px!important; height:20px!important; }
.driver_detail_con .d_loca_time,.driver_detail_con .p_loca_time { margin:0 0 0 12px!important; min-height: 45px!important; }
.driver_detail_con .loca_drop,.driver_detail_con .loca_picup { font-size:14px!important; line-height:17px!important; margin:0 0 3px!important; }
.driver_detail_con .loca_drop_time,.driver_detail_con .loca_picup_time { font-size:12px!important; line-height:16px!important; }
.driver_detail_con .sc_loca_picup:before { content:"...."!important; }
.driver_detail_con .loca_iconFlag .picup_blue { width:12px!important; }
.driver_detail_con .loca_icon { font-size:22px!important; line-height:24px!important; }
/* .driver_detail_con .special_req_con { padding:10px 0 10px!important; } */
.driver_detail_con .terms_row img { max-width:20px!important; }
.driver_detail_con .fs_16 { font-size:14px!important; line-height:18px!important; }
.driver_detail_con .pay_txt { font-size:14px!important; line-height:18px!important; }
.driver_detail_con .payrImg img { width:20px!important; }
.driver_detail_con .shipment_card_B { padding:10px 12px!important; min-height:50px!important; }
/* .driver_detail_con .s_user_img { min-width:36px!important; max-width:36px!important; min-height:36px!important; max-height:36px!important; } */
/* .driver_detail_con .s_user_img img { height:32px!important; width:32px!important; } */
.driver_detail_con .u_detail_value { font-size:14px!important; line-height:18px!important; }
.driver_detail_con .u_detail_lable { font-size:12px!important; line-height:14px!important; }
/* .driver_detail_con .icon_bg { width:36px!important; height:36px!important; } */
/* .driver_detail_con .mess_icon,.driver_detail_con .phone_icon { width:36px!important; height:36px!important; margin:0 0 0 8px!important; } */
.driver_detail_con .edit_ship_icon .dropdown-toggle img { max-width:18px!important; }
/* .driver_detail_con span.shipType { font-size:12px!important; height:22px!important; width:42px!important; margin:0 8px 0 0!important; } */
.driver_detail_con .sc_center .edit_shipmentr { margin:0!important; }
.driver_detail_con .ship_drivr_card .sc_center .edit_shipmentl { max-width:90%!important; }
.edit_location_con .custom_checkbox mat-mdc-form-field:first-child { width:75px; }
.edit_location_con .custom_checkbox mat-mdc-form-field:nth-child(2) { width:465px; }
.country_code_fld mat-mdc-select { min-width:83px; border-top-right-radius:0!important; border-bottom-right-radius:0!important; height:40px; border-right:1px solid #ddd!important; overflow:hidden; margin:1px 0; 
}
.country_code_fld input { border-top-left-radius:0!important; border-bottom-left-radius:0!important; }
.material-icons-outlined { font-display:swap; font-family:'Material Icons' !important;; }
/* .rating_long_card app-rating-view { display:block; width:100%; margin:-12px 0 13px } */
.rating_long_card .rvw_rtg_viw_edt { display: flex; align-items: center;}
.dri_rtg_list .rvw_rtg_viw_edt { display: flex; flex-direction: column;}
.driver_list_body .dri_rtg_list .rvw_rtg_viw_edt { display: flex; align-items: center; flex-direction: row; }
.driver_list_body .dri_rtg_list .rvw_rtg_viw_edt .total_reviews { margin-left: 5px; }
.rating_long_card .rating_stars .material-icons { font-size: 16px !important;}
.rating_long_card .total_reviews { margin-left: 10px !important; } 
.small_card_rating { margin:-3px 0 8px 0 }
.dri_profile_info .ship_side_panel_rating app-rating-view { margin:0 0 0 55px; display:block }
.ship_profile_info .ship_side_panel_rating app-rating-view { display:block; margin:3px 0 0 46px }
.cc_head_r .mat-mdc-radio-label-content { color:var(--clBlkb) }
.cc_head_r .mat-mdc-radio-checked .mat-mdc-radio-label-content { color:var(--clBlka) }
.tab_panel_con .mat-mdc-expansion-panel-header { min-height:68px; height:auto }
.tab_panel_con .mat-mdc-content { overflow:visible }
.tab_panel_con mat-mdc-expansion-panel { background:#fff; box-shadow:none!important; border-radius:10px!important; border:1px dashed #cbcbcb }
.tab_panel_con .mat-mdc-expansion-indicator::after { color:var(--clBlua); border-width:0 3px 3px 0; border-radius:2px; padding:4px }
.edit_card_box .custom_input { padding:11px 12px!important }
.side-show-panel .tab_panel_con .line_before { font-size:13px!important; line-height:17px!important; padding:2px 0 2px 12px!important; margin:0 0 0 -12px!important }
.side-show-panel .tab_panel_con .tpc_box { padding:6px 8px!important; margin:0 -12px }
.side-show-panel .coi_docl img { max-width:32px }
.side-show-panel .tab_panel_con .mat-mdc-expansion-panel-header { padding:6px 12px }
.inpt_checkbox mat-mdc-checkbox { max-width:40px }
.inpt_checkbox .mat-mdc-form-field-infix { display:flex }
.hint_l_side .mat-mdc-form-field-hint-spacer { display:none }
.cus_backdrop_class { background:#000000a6 }
.cus_popup_vw .mat-mdc-dialog-container { box-shadow:none; background:snow }

.loadHistory_panel app-trip-history .line_before { margin:0 0 30px -16px }
.loadHistory_panel .event_dateTxt { min-width:58px!important; margin:0 6px 0 0!important }
.loadHistory_panel .event_date_yr { font-weight:500!important; font-size:12px!important; line-height:15px!important; min-height:34px!important; text-align:right }
.loadHistory_panel .event_date { font-size:12px!important; line-height:15px!important; font-weight:500!important }
.loadHistory_panel .load_icon { min-width:38px!important; min-height:38px!important; max-width:38px!important; margin:0 12px 0 0!important }
.loadHistory_panel .load_icon img { max-width:22px!important }
.loadHistory_panel .load_his_info:after { left:82px!important }
.loadHistory_panel .list_row { margin:0 0 14px!important }
.loadHistory_panel .event_time { padding:4px 8px!important; max-width:270px!important; width:100% }
.loadHistory_panel .event_title { font-size:14px!important; line-height:16px!important; margin:0 0 0!important }
.loadHistory_panel .trip_history_sidePanel .event_title { width:100%!important; max-width:270px!important }
.loadHistory_panel .scnd_lable_row_con p { line-height:19px }
.loadHistory_panel .scnd_lable_row_con .event_title { font-size:13px!important; line-height:15px!important }
.loadHistory_panel .slrc_icon { min-width:38px!important; min-height:38px!important; max-width:38px!important; margin:0 9px 0 0!important }
.loadHistory_panel .trip_history_sidePanel .location_detailTxt { max-width:206px!important }
.loadHistory_panel .trip_history_sidePanel .scnd_lable_row_con .location_detailTxt { max-width:160px!important }
.loadHistory_panel .slrc_row:after { width:16px!important; height:16px!important; left:-48px!important; top:8px!important }
.loadHistory_panel .load_his_head { display:block!important; margin:0 0 22px }
.loadHistory_panel .load_his_head .custom_checkbox { margin:6px 0 0 8px }
.sidePanel_tab app-document-multiple .detail_card_con { box-shadow:none; padding:0; border-radius:0; margin:0 }
.sidePanel_tab app-document-multiple .detail_card_con .add_doc_action { padding:8px 5px }
.sidePanel_tab app-document-multiple .detail_card_con .add_doc_action span { font-size:12px!important }
.loadHistory_panel .updated_value_box_2 .sub_point_time { left:-98px!important }

.card_container .tab_buttons .mat-mdc-horizontal-stepper-header { padding:0 0; border-radius:24px }
.card_container .tab_buttons .mat-mdc-step-label-selected { color:var(--clWhta)!important; width:100%; height:100%; align-items:center; justify-content:center; background:var(--clBlua); border-radius:8px; color:var(--clWhta)!important }
.card_container .tab_buttons .cus_toggle.mat-mdc-button-toggle-group,
.card_container .tab_buttons .cus_toggle.mat-mdc-button-toggle-standalone { padding:0; border:none }
.card_container .tab_buttons .mat-mdc-button-toggle-button { border:1px solid #818181; border-radius:5px; font-weight:500; color:#818181 }
.card_container .tab_buttons .cus_toggle .mat-mdc-button-toggle-checked .mat-mdc-button-toggle-button { background-color:var(--clBlua); border-radius:5px; color:var(--clWhta)!important; border:1px solid #3787e6 }

.overview_preferred_regions .mat-mdc-horizontal-stepper-header { padding:0 0; border-radius:24px }
.overview_preferred_regions .mat-mdc-step-label-selected { color:var(--clWhta)!important; width:100%; height:100%; align-items:center; justify-content:center; background:var(--clBlua); border-radius:8px; color:var(--clWhta)!important }
.overview_preferred_regions .cus_toggle.mat-mdc-button-toggle-group,
.overview_preferred_regions .cus_toggle.mat-mdc-button-toggle-standalone { padding:0; border:none }
.overview_preferred_regions .mat-mdc-button-toggle-button { border:1px solid #818181; border-radius:5px; font-weight:500; color:#818181 }
.overview_preferred_regions .cus_toggle .mat-mdc-button-toggle-checked .mat-mdc-button-toggle-button { background-color:var(--clBlua); border-radius:5px; color:var(--clWhta)!important; border:1px solid #3787e6 }

.mat-mdc-pseudo-checkbox { color:#d9d9d9 }
.mat-mdc-pseudo-checkbox.mat-mdc-pseudo-checkbox-checked,
.mat-mdc-pseudo-checkbox.mat-mdc-pseudo-checkbox-indeterminate { border-color:#d9d9d9 }
.mat-mdc-selected .mat-mdc-pseudo-checkbox.mat-mdc-pseudo-checkbox-checked,
.mat-mdc-selected .mat-mdc-pseudo-checkbox.mat-mdc-pseudo-checkbox-indeterminate { border-color:#3787e6 }
.mat-mdc-primary .mat-mdc-pseudo-checkbox-checked,
.mat-mdc-primary .mat-mdc-pseudo-checkbox-indeterminate { background:#fff }
.mat-mdc-pseudo-checkbox-checked::after { border-left:2px solid #3787e6!important }
.mat-mdc-pseudo-checkbox::after { border-bottom:2px solid #3787e6!important }
.mat-mdc-active .mat-mdc-option-text { color:#666 }
.mat-mdc-selected .mat-mdc-option-text { color:#3787e6 }
.subscription-billing .mat-mdc-progress-bar-primary.mat-mdc-progress-bar-fill.mat-mdc-progress-bar-element { transform:scale3d(.6,1,1)!important }
.subscription-billing .mat-mdc-progress-bar-fill::after { background-color:#3787e6 }
.mat-mdc-progress-bar-buffer { background-color:#22b0fc }
.subscription-billing .mat-mdc-progress-bar { height:10px!important; width:40%!important; margin:17px 0 }
.mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-ink-bar,
.subscription-billing .mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar { background-color:#3787e6!important }
.subscription-billing .mat-mdc-tab-header,
.subscription-billing .mat-mdc-tab-nav-bar { border:none }
.subscription-billing .mat-mdc-slide-toggle-label { flex-direction:row-reverse!important; justify-content:space-between; width:171px }
.subscription-billing .mat-mdc-slide-toggle-content { font-size:15px; font-display:swap; font-weight:500; color:#818181 }
.payment_method_box .payment_add_card { margin-bottom:0!important }
.input_icon_align .mat-mdc-form-field-flex { align-items:center }

.notification_list .mat-mdc-expansion-panel-header-title { border-left:5px solid var(--clBlua); padding:5px 0 5px 19px; display:flex; align-items:center; font-size:20px; font-weight:600; color:var(--clBlka); position:absolute; left:1px; top:4px }
.subscription-billing .mat-mdc-slide-toggle-label { flex-direction:row-reverse!important; justify-content:space-between }
.subscription-billing .mat-mdc-slide-toggle-content { font-size:15px; font-display:swap; font-weight:500; color:#818181; margin:0 20px }
.red_strip .custom_toggle .mat-mdc-slide-toggle-content { color:#fff!important }
.insurance_step_con .mat-mdc-form-field-flex { display:inline-flex; align-items:center }

.subscription-billing .cus_matTab .mat-mdc-tab-header { padding:0 }
.prifix_txt_align mat-mdc-form-field .mat-mdc-form-field-flex { align-items:center }
.sign_in_con .ic_password .mat-mdc-form-field-flex { align-items:center }
.Subscription_plan_box .mat-mdc-radio-outer-circle { border-color:#D9D9D9 !important; }
.crt_Ship_body .p_reletive { position:relative }
.crt_Ship_body .back_btn { position:absolute; left:1%; border-radius:10px; bottom:23px; height:50px; width:180px; background:#818181 }
.crt_Ship_body .accept_btn { border-radius:10px; height:50px; width:180px }
.Subscription_plan_box .mat-mdc-radio-checked .mat-mdc-radio-label-content { color:#818181 }
.payment_method_box #card-info .text-center { display:block }

@media screen and (max-width:676px) {
  .payment_method_box #card-info .row { flex-wrap:inherit; overflow:auto }
  .payment_method_box #card-info .row::-webkit-scrollbar { height:0 }
  .payment_method_box #card-info .row .col-md-4 { width:70% }
  .payment_method_box #card-info .text-center { display:none }
  .payment_add_card { position:relative }
  .payment_add_card .payment_cardAdded { margin:0 0 24px!important }
  .payment_add_card .material-icons { font-size:22px!important }
  .payment_add_card .add_bill_info { /* position:absolute; right:10px; bottom:0;  */ flex-direction:row!important; border:none!important; min-height:auto!important; background:0 0!important; margin:0!important }
  .add_bill_info p { font-size:16px!important; margin:0 0 0 4px }
}

.mob_responsive_subscription { background:#fff!important }
.mob_responsive_subscription .main-panel { background:#fff!important }
.er_mess_flag_drop { position:absolute; width:161px; bottom:-14px }
.edit_location_inputes .mat-mdc-form-field-subscript-wrapper { left:-72px }
.c_flag_img { width:auto; height:18px }
.add_location_inputes .mat-mdc-form-field-infix:first-child { width:100%!important }
.edit_location_inputes .mat-mdc-form-field-infix:first-child { width:100%!important }
.bdr_l_0 .custom_input { border-top-left-radius:0!important; border-bottom-left-radius:0!important }
.num_country_code { display:flex; align-items:center }
.num_country_code mat-mdc-form-field:first-child { width:85px }
.num_country_code mat-mdc-form-field:last-child { width:calc(100% - 85px) }
.num_country_code .country_code_fld input {   margin-top: -3px !important; }
.shipper_ship_create .mat-mdc-form-field-infix { width:100%!important }
.shipper_ship_create .country_code_fld mat-mdc-select { margin-top:-1px }
.order_summary_b .mat-mdc-radio-label-content { color:#818181; font-size:21px }
.num_country_code .mat-mdc-form-field-subscript-wrapper { position:absolute; left:-83px }

#ship_car_tabs .mat-mdc-tab-header { padding-left:0 }
#ship_car_tabs .location_added_card_con.col-md-3 { width:25%!important; margin-left:0 }
.create_lanes_p .mat-mdc-tab-header { border-bottom:none }
.create_lanes_p .c_lanes_sub_tabs .mat-mdc-focus-indicator { font-size:17px; background:#fff; opacity:1; border-radius:40px; color:#fff; color:#000 }
.create_lanes_p .c_lanes_sub_tabs .mat-mdc-ink-bar { display:none }

.create_lanes_p .mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar,
.mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-ink-bar { background-color:#3787e6!important }
.create_lanes_p .mat-mdc-form-field-infix { padding:0 }
.create_lanes_p .dropdown-toggle::after { display:none }
.create_lanes_p .mat-mdc-checkbox-indeterminate.mat-mdc-accent .mat-mdc-checkbox-background,
.mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background { background:#3787e6!important }
.create_lanes_p .mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle { border-color:#3787e6 }
.create_lanes_p .mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle,
.mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-ripple .mat-mdc-ripple-element:not(.mat-mdc-radio-persistent-ripple),
.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-persistent-ripple,
.mat-mdc-radio-button.mat-mdc-accent:active .mat-mdc-radio-persistent-ripple { background-color:#3787e6 }

.mat-mdc-active .mat-mdc-option-text { color:#3787e6!important }
.side_panel_manage .fixed-top { z-index:0!important }
.booking_lanes { overflow-x:hidden }
.booking_lanes .booking_lane_container .mat-mdc-tab-body-wrapper { overflow:visible }
.booking_lanes .booking_lane_container .mat-mdc-tab-body-wrapper { overflow:visible }
.booking_lanes .booking_lane_container .mat-mdc-tab-body.mat-mdc-tab-body-active { overflow:visible }
.booking_lanes .booking_lane_container .mat-mdc-tab-body-content { overflow:visible }
.booking_lanes .booking_lane_container .filter_lanes_input_bx { position:relative; box-shadow:none }
.booking_lane_container .custom_input { padding: 4px 16px!important; font-size: 12px !important;  }
.booking_lane_container .custom_input::placeholder { font-size: 12px !important; }

.booking_lanes .booking_lane_container .filter_lanes_input_bx:before { content:'';  width:200vw;  height:100%;  top:0;  background:#fff;  left:-50vw;  z-index:-1;  box-shadow:0 8px 16px 0 #041a3433 }
.view_bids_tabs mat-mdc-ink-bar { display: none !important; }
.mat-mdc-active .mat-mdc-option-text { color:#3787e6!important }
.user_pro_banner .carrierN_img img { height: 73px!important; width: 73px!important; border-radius:50% }
.user_pro_banner { margin-top:30px!important }
.mat_dropdown.mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) { background:rgba(0,0,0,.12) }

.shipment_bidding .side-show-panel { position:fixed; background:#000000bf; width:100%; height:100vh; right:0; z-index:12; top:0; display:block; overflow:hidden }
/* driver list component css */
.dri_rtg_list .total_reviews { font-size: 9px !important;}
.dri_rtg_list .rvw_rtg_viw_edt { display:flex; }
.dri_rtg_list .rvw_rtg_viw_edt .rating_box {line-height: 16px; }
.dri_rtg_list .rvw_rtg_viw_edt .rating_box .rating_stars .material-icons { font-size: 13px; line-height: 12px }
.confirm_lane_empty .Empty_State_body { margin:0; }
.confirm_lane_filtter .selected_filter_con { margin-top:22px!important; }
#driver_rating .rating_stars .material-icons { font-size:20px!important; }
#driver_rating .total_reviews { text-align:center; }
#rating_review_popup_one .rating_one .material-icons { font-size: 15px; }
/* #qute_bid_container .mat-mdc-form-field-infix { padding:0!important; } */
.progress_input_box .mat-mdc-slider-horizontal { height:40px!important; min-width: 109%!important; padding:0; }
.progress_input_box .mat-mdc-slider-track-fill { background-color:#3787e6!important; }
.progress_input_box .mat-mdc-slider-thumb { background-color:#f0f0f0!important; border:none; width:32px!important; height:32px!important; }
.progress_input_box .mat-mdc-slider-horizontal .mat-mdc-slider-track-background { height:15px!important; }
.progress_input_box .mat-mdc-slider-horizontal .mat-mdc-slider-track-fill { height:15px!important; }
.progress_input_box .mat-mdc-slider-horizontal .mat-mdc-slider-track-wrapper { border-radius:40px; height:15px; }

.bid_assistant_flow .input_box .mat-mdc-form-field-wrapper { padding-bottom:0!important; }
.bid_assistant_flow .custum_input_css .mat-mdc-input-element { background:#fff; padding:0; }
.bid_assistant_flow .mat-mdc-slider-thumb { bottom:-23px; }
.gbw_box .box3 .mat-mdc-form-field-wrapper { padding-bottom:0; }
.gbw_box .box3 .mat-mdc-form-field-infix { padding:0; margin:-10px 5px 0 5px!important; }

@media screen and ( max-width:1023px ) {

  .gbw_box .box3 .mat-mdc-form-field-infix { width: 100px;}
  .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination { display:none !important; }
  
}
.assits_popup .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination { display:none; }
.assits_popup .mat-mdc-ink-bar { height:0!important; }
.assits_popup .mat-mdc-tab-header { border-bottom:none!important; }

.assits_popup .mat-mdc-slider-track-fill { background-color:#3787e6!important; }
.assits_popup .mat-mdc-slider-thumb { background-color:#f0f0f0!important; border:none; width:26px!important; height:26px!important; right:-15px; bottom:-17px!important; }
.assits_popup .mat-mdc-slider-horizontal { min-width:200px; }
.assits_popup .mat-mdc-slider-horizontal .mat-mdc-slider-track-background { height:10px!important; }
.assits_popup .mat-mdc-slider-horizontal .mat-mdc-slider-track-fill { height:10px!important; }
.assits_popup .mat-mdc-slider-horizontal .mat-mdc-slider-track-wrapper { border-radius:40px; height:10px; }
.bid_assistant_flow .mat-mdc-expansion-panel .mat-mdc-expansion-panel-content { background:#fff; }
.bid_assistant_flow .mat-mdc-expansion-panel .mat-mdc-expansion-panel-body { padding:25px 43px; background: #fff; }

@media screen and (max-width:767px) {

  .bid_assistant_flow .mat-mdc-expansion-panel .mat-mdc-expansion-panel-body { padding: 25px 15px; }
  .mat-mdc-tab-label .mat-mdc-tab-label-content { font-size: 10px !important;}
  
}
.bid_assistant_flow .mat-mdc-expansion-panel .mat-mdc-expansion-panel-header.mat-mdc-expanded { height:60px; }
.bid_assist_popup .mat-mdc-expansion-panel .mat-mdc-expansion-panel-content { background:#fff; }
.bid_assist_popup .mat-mdc-expansion-panel .mat-mdc-expansion-panel-body { padding:25px 20px; }
.bid_assist_popup .mat-mdc-expansion-panel .mat-mdc-expansion-panel-header.mat-mdc-expanded { height:60px; }
.assits_popup .mat-mdc-slider-horizontal { min-width:143px; }
.progress_lab_bar mat-mdc-form-field .mat-mdc-form-field-wrapper { padding:0 0 3px 0 }
#bid_qoute_page .mat-mdc-expansion-panel .bid_qoute_page { max-width:85%!important; }
#bid_qoute_page .mat-mdc-expansion-panel input { max-width:40px; height:15px; text-align:center; }
#bid_qoute_page .mat-mdc-expansion-panel.mat-mdc-expanded { margin:0 -22px!important; }
#bid_qoute_page .mat-mdc-expansion-panel:last-child { margin-bottom:0!important; }
#bid_qoute_page .mat-mdc-expansion-panel { margin:0 -22px; border-radius:0; background:#f0f0f0; }
#bid_qoute_page .mat-mdc-expansion-panel-header-title { max-width:294px; }
#bid_qoute_page .mat-mdc-expansion-panel-header-description { color:#3787e6; font-size:20px; font-style:normal; font-weight:600; line-height:normal; }
#bid_qoute_page .mat-mdc-expansion-panel { margin-bottom: 20px !important;}
#bid_qoute_page .mat-mdc-expansion-panel { margin: 0 -22px; border-radius: 0px; background: #F0F0F0;}
#bid_qoute_page .mat-mdc-expansion-panel .mat-mdc-expansion-panel-content { background:#fff!important; }
#bid_qoute_page .progress_input_box { margin:0 -34px; }
#qoute_bid_popup .mat-mdc-expansion-panel .qoute_bid_popup { max-width:85%!important; }
#qoute_bid_popup .mat-mdc-expansion-panel { margin-bottom:20px!important; }
#qoute_bid_popup .mat-mdc-expansion-panel { margin:0 -22px; border-radius:0; background:#f0f0f0; box-shadow: none; }
#qoute_bid_popup .mat-mdc-expansion-panel-header-title { max-width:294px; }

#shipment_card_Q .dropdown-menu .dropdown-item { min-height:22px; }
#shipment_card_Q .loca_iconFlag .picup_blue { width:11px; }

#shipment_card_ratting .rating_stars .material-icons { font-size:16px }
#shipment_card_ratting .total_reviews { font-size:9px; text-align:center }
.bid_assist_popup .mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle { border-color:#3787e6; }
.bid_assist_popup .mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle { background-color:#3787e6; }

.progress_lable input { width:55px; max-width:65px; }
.bid_assistant_flow .progress_lable input { width:64px; max-width:65px; text-align:right; }
.progress_lable input:focus { background:#dfe1e6!important; text-align:center; border-radius:5px; }
.bid_assistant_flow .progress_lable input:focus { max-width:60px; height:25px; text-align:left; padding: 0 10px; }



.vv_first_time_popup { max-height: calc(100vh - 119px) !important ;}
.progress_lable input { width: 55px; max-width: 65px;}
.progress_lable input:focus { background: #dfe1e6 !important; text-align: center; border-radius: 5px; }
.vv_first_time_popup .assits_popup .mat-mdc-slider-thumb { right: -10px;}
.vv_first_time_popup .progress_lable .fs_16 { font-size: 12px !important;}
.vv_first_time_popup .progress_input_box .mat-mdc-slider-horizontal { height: 40px !important; min-width: 311px !important; } 
.vv_first_time_popup .custum_input_css .mat-mdc-form-field-wrapper { padding-bottom: 10px; }
.vv_first_time_popup #qoute_bid_popup .mat-mdc-expansion-panel-header-description { font-size: 16px; } 
.bid_assistant_flow .progress_lable input:focus { max-width: 60px; height: 25px; text-align: left; }
.bid_assistant_flow .progress_lable input { width: 64px; max-width: 65px; text-align: right;}
.vv_first_time_popup .mat-mdc-expansion-panel-body { padding:15px 24px; background:#fff; }
.edit_popup_comodity .col-md-3:nth-child(5) .mat-mdc-input-element { padding:10px!important; }
.fixed-top { z-index:5!important; }

.list_option::before { color:#818181; right:-7px; top:-5px; position:absolute; }
.gbw_box .mat-mdc-radio-inner-circle { background-color:#3787e6!important; }
.mat-mdc-radio-outer-circle { border-color:#3787e6!important; border-width: 1px !important; }

.bid_assistant_flow .progress_lable input:focus { max-width:70px!important; height:25px!important; margin:11.5px 0; }
.bid_assistant_flow .input_box .custum_input_css .mat-mdc-input-element { text-align:left; }
.custum_input_css .mat-mdc-accordion .mat-mdc-expanded:last-child { box-shadow:none; }
.update_bid_quote .mat-mdc-slider-horizontal .mat-mdc-slider-wrapper { top:0 }
.update_bid_quote .mat-mdc-form-field-subscript-wrapper { top:50px }
.update_bid_quote .mat-mdc-expansion-panel-header-description { max-width:100px }
.update_bid_quote .mat-mdc-expansion-panel-header-title { max-width:234px!important }
.update_bid_quote .custum_input_css .mat-mdc-form-field-wrapper { padding-bottom:5px }
.update_bid_quote .progress_input_box .mat-mdc-slider-horizontal { height:10px!important }
.update_bid_quote .p_absulute_45 { right:55px!important }

/* quote_bids_tabs */
#booking_tab .mat-mdc-tab-header { max-width: 400px;}
.quote_bids_tabs .mat-mdc-tab-header { padding: 0px; }
.create_lanes_p .Empty_State_body { margin: 40px 15px 0px;}
.create_lanes_p .mt-4 .selected_filter_con { margin-top: 20px;}
.lane_filtter .selected_filter_con .label_txt {  max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.mat-mdc-active .mat-mdc-option-text { color: #3787E6 !important; }
.referral_beni_body .mat-mdc-tab-header { margin: 0 30px; }

@media screen and (max-width:767px) {
  .referral_beni_body .mat-mdc-tab-header { margin: 0 15px; }

}

/* booking lane filter */
.booking_lanes { overflow-x: hidden; }
.booking_lanes .booking_lane_container .mat-mdc-tab-body-wrapper { overflow: visible; }
.booking_lanes .booking_lane_container .mat-mdc-tab-body.mat-mdc-tab-body-active { overflow: visible; }
.booking_lanes .booking_lane_container .mat-mdc-tab-body-content { overflow: visible; }

/* lane css */
.view_bids_tabs mat-mdc-ink-bar { height: 5px !important;}
.mat-mdc-active .mat-mdc-option-text { color: #3787E6 !important; }
.user_pro_banner .carrierN_img img { height: 50px !important; width: 50px !important; border-radius: 50%; }
.user_pro_banner { margin-top: 30px !important;}

/* popup css */
.mat_dropdown.mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) { background: rgba(0,0,0,.12); }

/* carrier side panel css */
.shipment_bidding .side-show-panel { position: fixed; background: #000000bf; width: 100%; height: 100vh; right: 0px; z-index: 12; top: 0; display: block; overflow: hidden;}
.confirm_lane_empty .Empty_State_body { margin: 0px; }
.confirm_lane_filtter .selected_filter_con { margin-top: 22px !important;}

#driver_rating .rating_stars .material-icons { font-size: 20px !important; }
#driver_rating .total_reviews { text-align: center; }

/* quote bid assitant css */

.bid_assistant_flow .input_box .mat-mdc-form-field-wrapper { padding-bottom: 0px !important;}
.bid_assistant_flow .custum_input_css .mat-mdc-input-element { background: #FFF; padding: 0px; }
.bid_assistant_flow .mat-mdc-slider-thumb { bottom: -23px;}

/* box three calculater css */
.gbw_box .box3 .mat-mdc-form-field-wrapper { padding-bottom: 0px; }
.gbw_box .box3 .mat-mdc-form-field-infix { padding: 0; margin: -10px 5px 0 5px !important;  }

/* assistant popup css */
.assits_popup .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination { display: none;}
.assits_popup .mat-mdc-ink-bar { height: 0px !important;}
.assits_popup .mat-mdc-tab-header { border-bottom: none !important;}

/* forprogress bar */
.assits_popup .mat-mdc-slider-track-fill { background-color: #3787E6 !important;}
.assits_popup .mat-mdc-slider-thumb { background-color: #F0F0F0 !important; border: none; width: 26px !important; height: 26px !important; right: -15px; bottom: -17px !important; }
.assits_popup .mat-mdc-slider-horizontal { min-width: 200px; }
.assits_popup .mat-mdc-slider-horizontal .mat-mdc-slider-track-background { height: 10px !important; }
.assits_popup .mat-mdc-slider-horizontal .mat-mdc-slider-track-fill { height: 10px !important;}
.assits_popup .mat-mdc-slider-horizontal .mat-mdc-slider-track-wrapper { border-radius: 40px; height: 10px;}

/* expansion panel css for qoute bid */
.bid_assistant_flow .mat-mdc-expansion-panel .mat-mdc-expansion-panel-content { background: #fff;}
.bid_assistant_flow .mat-mdc-expansion-panel .mat-mdc-expansion-panel-body { padding: 25px 43px 15px 43px; }
.bid_assistant_flow .mat-mdc-expansion-panel .mat-mdc-expansion-panel-header.mat-mdc-expanded { height: 60px; }

.bid_assist_popup .mat-mdc-expansion-panel .mat-mdc-expansion-panel-content { background: #fff;}
.bid_assist_popup .mat-mdc-expansion-panel .mat-mdc-expansion-panel-body { padding: 25px 20px; }
.bid_assist_popup .mat-mdc-expansion-panel .mat-mdc-expansion-panel-header.mat-mdc-expanded { height: 60px; }
.assits_popup .mat-mdc-slider-horizontal { min-width: 143px; }

/* bid qoute page expation panel css */
.bid_assist_popup .mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle { border-color: #3787e6; }
.bid_assist_popup .mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle { background-color: #3787e6; }

/* vv_first_time popup css */
#qoute_bid_popup .mat-mdc-expansion-panel input { text-align: right; padding: 5px; height: 14px; border-radius: 5px; font-weight: 600;} /* max-width: 114px;  */
#qoute_bid_popup .mat-mdc-expansion-panel-body .col-md-6 { margin-bottom: 20px;} 
#qoute_bid_popup .mat-mdc-expansion-panel.mat-mdc-expanded { margin: 0px -22px !important; margin-top: 10px !important; }
#qoute_bid_popup .mat-mdc-expansion-panel { margin-bottom: 20px !important; }
#qoute_bid_popup .mat-mdc-expansion-panel { margin: 0 -22px; border-radius: 0px; background: #F0F0F0; }
#qoute_bid_popup .mat-mdc-expansion-panel-header-title { max-width: 294px; }
#qoute_bid_popup .mat-mdc-expansion-panel-header-description { color: #3787E6; font-size: 20px; font-style: normal; font-weight: 600; line-height: normal; }
#qoute_bid_popup .mat-mdc-expansion-panel .mat-mdc-expansion-panel-content { background: #fff !important;}
#qoute_bid_popup .progress_input_box { margin: 0 -34px;}
#qoute_bid_popup .progress_lable input:focus { height: 14px !important; margin: 11.5px 0px; text-align: left; background: none;} /* max-width: 60px !important; */

#shipment_card_Q .dropdown-menu .dropdown-item { min-height: 22px; }
#shipment_card_Q .loca_iconFlag .picup_blue { width: 11px;}


.gbw_box .mat-mdc-radio-inner-circle { background-color: #3787E6 !important; }
.mat-mdc-radio-outer-circle { border-color: #D9D9D9 !important; background: #fff; }
.progress_input_box .mat-mdc-expansion-indicator::after { padding: 6px;}

/* bid_assistant_flow */
.bid_assistant_flow .progress_lable input:focus { max-width: 70px !important; height: 25px !important; margin: 11.5px 0px; }
.bid_assistant_flow .input_box .custum_input_css .mat-mdc-input-element { text-align: left;} 
.custum_input_css .mat-mdc-accordion .mat-mdc-expanded:last-child { box-shadow: none;}
.guide_nav_card::-webkit-scrollbar { width: 0px; height: 0px }

#topnav { position:relative }
.tutorial_wight_box .mob_view .vertical_scrollBar::-webkit-scrollbar { width:1px }

.guide_nav_card::-webkit-scrollbar { width:0px; height:0px }

@media screen and (max-width:1023px) {
    app-shipment-card app-rating-view { margin:0 }
    #shipment_card_ratting .rating_stars .material-icons { font-size:14px }
}

@media screen and (max-width:676px) {
  #topnav {  position: relative }
  .tutorial_wight_box .mob_view .vertical_scrollBar::-webkit-scrollbar {  width: 1px }
  .tutorial_wight_box .row .vertical_scrollBar:after { border: none !important; }
}

@media screen and (max-width:1023px) {
    .fixed-top { position:relative }
    #topnav .container { max-width:88% }
    .custom_menu ul li a { font-size:8px!important; line-height:12px!important; padding:25px 8px!important }
    .logo-img { min-width:100px!important; max-width:104px!important }
}

@media screen and (min-width:1024px) and (max-width:1300px) {
  .fixed-top { position:relative }
  #topnav .container { max-width:88% }
  .custom_menu ul li a { font-size:8px!important; line-height:12px!important; padding:25px 8px!important }
  .logo-img { min-width:100px!important; max-width:104px!important }
}

.dark_mob_view { overflow:hidden }
app-shipment-view-top-section .shipment_detailPage .btn_crt .bt { background:#3787e6!important }

.tutorial_wight_box .vertical_scrollBar::-webkit-scrollbar { width: 0px;}
.tutorial_wight_box .row .vertical_scrollBar:after { content: ''; width: 0; height: 100%; position: absolute; border: 2px solid #ECECECCC; top: 0; left: 0; right: 0px; }

/* shipper subscription css */
.pct_c .mat-mdc-radio-outer-circle  { border-color: #D6D6D6 !important; border-width: 1px !important; }
.pct_c .mat-mdc-radio-inner-circle { left: -3px !important; top: -3px !important; height: 26px !important; width: 26px !important; }
.pct_c .mat-mdc-radio-button { width: 170px;}

.left_side_panel .mat-mdc-expansion-panel-body { padding: 0; }
.left_side_panel .mat-mdc-expanded .mat-mdc-expansion-indicator::after { color: #fff; }
.fixed-top { position: relative; }

/* horizontal_stepper */
.rounded_h_tab { margin: 0 20px; }
.right_container_scroller { display: block; width: 100%; overflow-y: auto; height: calc(100% - 55px); }
app-shipment { display: block; height: calc(100% - 22px); width: 100%; overflow-y: auto; }

/* notification_con_box */
.mat-mdc-tab-label-container { flex-grow: inherit !important; }
.mat-mdc-tab-label { height: 30px !important; background: #fff; border: 1px solid #D9D9D9; border-radius: 40px; opacity: 1 !important; min-width: auto; margin-right: 10px; min-width: auto !important;}
.message_overview .mat-mdc-ink-bar { height: 0px;}
/* .cus_matTab .mat-mdc-tab-header { padding: 0 22px; } */
.cus_matTab .mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar  { display: none;}
.notification_con_box .mat-mdc-form-field-wrapper { padding-bottom: 8px; }
/* .notification_list .mat-mdc-tab-body-wrapper { margin-top: 25px; } */
.cus_matTab .mat-mdc-tab-label-active .mat-mdc-tab-label-content { color: var(--clBlka); }
.nav_notifications .cus_matTab .mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar { background-color: var(--clBlua); }

/* network-component-css */
.network_container .cus_matTab .mat-mdc-tab-header { display: none; }

/* New side-panel: filter box in top navbar */
.network_sugBox .wapper_body .wapper_body_rb { overflow: visible; height: auto; padding: 0; }
.massages_sugBox .wapper_body .wapper_body_rb {  overflow: visible; height: auto; padding: 0; }
 @media screen and (min-width:1300px) and (max-width:1400px) {
  .massages_sugBox .wapper_body .wapper_body_rb {  overflow: auto; height: calc(100vh - 100px); }
  
 }
/* .storage_filter_box .wapper_body .wapper_body_rb { overflow: visible; height: auto; padding: 0; } */

/* messages sub tabs css */
.message_overview .sub_tabs .mat-mdc-tab-label-active { background: transparent !important; color: #000 !important; border-bottom: 2px solid #3787e6 !important; border-radius: 0px !important; }
.message_overview .sub_tabs .mat-mdc-tab-label { background: transparent !important; border: none; }
.message_overview .sub_tabs .mat-mdc-tab-label-active .mat-mdc-tab-label-content { color: #000 !important; }
.message_overview .sub_tabs .mat-mdc-tab-header { margin-top: 10px; }

.filter_input_bx .custom_input::placeholder { font-size: 12px !important; font-weight: 400 !important; line-height: 16px !important; }
.filter_input_bx .custom_input { font-size: 12px !important; font-weight: 400 !important; line-height: 16px !important; padding: 6px 10px !important; border: 1px solid #D9D9D9 !important; background: #fff !important; }
.filter_input_bx .mat-mdc-select-placeholder,
.filter_input_bx input::placeholder { font-size: 12px; font-weight: 400 !important; }
.filter_input_bx .custom_input .mat-mdc-select-value-text { font-size: 12px; font-weight: 400 !important; }

@media (max-width:767px) {
  html { font-size:12px }
  .custum_button { min-width:5.429rem }
  .view_head_left { flex-basis:15% }
  .view_head_right { flex-basis:85% }
  .edit_card_body { padding:1.429rem }
  .info_header_right { flex-basis:100%; margin:0 }
}

@media (max-width:620px) {
  .vertical_tab { flex-direction:column }
  .tab_list { flex-basis:100%; flex-direction:column }
  .srch_top { max-width:inherit }
  .list-group { flex-direction:row; margin:15px 0 }
  .tab_list .list-group-item.active { background-color:#d4e5fb; border-bottom:2px solid }
  .tab_list .list-group-item { line-height:1rem; padding:10px 2px; width:100%; text-align:center }
  .tab_list .list-group-item.active:before { display:none }
  .list-group-item:last-child { margin-bottom:auto }
  .tabcontent { margin:0; flex-basis:100% }
  .list_scroll { height:calc(100vh - 170px); overflow-x:auto }
  .custum_button { font-size: 12px; padding: 5px 16px; }
  .Empty_State_body { padding: 20px 10px; }
  .Empty_State_img img { max-width: 80px;}
  .Empty_State_con .fs_16 { font-size: 12px !important; }
  .rating_long_card .rating_stars .material-icons { font-size: 10px !important; }

  /* pagination button display none */
  .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination { display: none !important; }


  /* payment card component css */
  // .cus_matTab .mat-mdc-tab-label-content { font-size: 8px; height: auto; }
  .mat-mdc-tab-label { height: 25px !important; padding: 2px 11px !important;}

}

@media (min-width:768px) and (max-width:1366px)
 { .upload_certificate_pop { width: 32vw !important } }


.network_main_content_body .mat-mdc-slide-toggle-bar { background-color: #D9D9D9; }
.mat-mdc-expanded .mat-mdc-expansion-indicator::after { position: relative; top: -4px; }
.map_cards_container app-skeleton-card .skelaton_con { margin-bottom: 10px;}


.before_login_user .wapper_body_r.col-md-10 { width: 100%; }

.wapper_body_rb { display: block; width: 100%; height: calc(100vh - 55px) ; overflow-y: auto ; }
.side_mess_tab .mat-mdc-tab-label { padding: 0 30px !important;}


/* shipper transaction tab css */
.shipper_transaction_header .mat-mdc-tab-header { margin: 0 30px;}


@media screen and (max-width: 767px) {
  
  .initial_r_64 { min-width: 35px; max-width: 35px; height: 35px; border-radius: 50%; font-size: 14px; line-height: 26px; font-weight: 500; }
}



/* upcomming sub tabs css */
.upcomming_sub_tabs .sub_tabs .mat-mdc-tab-label-active { background: transparent !important; color: #000 !important; border-bottom: 2px solid #3787e6 !important; border-radius: 0px !important; }
.upcomming_sub_tabs .sub_tabs .mat-mdc-tab-label { background: transparent !important; border: none; }
.upcomming_sub_tabs .sub_tabs .mat-mdc-tab-label-active .mat-mdc-tab-label-content { color: #000 !important; }
.upcomming_sub_tabs .sub_tabs .mat-mdc-tab-header { margin-top: 10px; }



/* driver details css */
.l_detail_card .rvw_rtg_viw_edt { display: flex; justify-content: space-between; }

/* new shipment list */
.filter_search .mat-mdc-input-element { font-size:12px; } 
.new_shipment_list_body .custom_input { padding: 8.5px 16px!important; font-size: 12px !important; line-height: 19px !important; background: #fff !important; margin-top: 0px; }
.new_shipment_list_body .user_search { border: 1px solid #d9d9d9; border-radius: 5px 0px 0px 5px; }

/* self_update_phone_input */
.self_update_phone_input input.mat-mdc-input-element { border-radius: 0 5px 5px 0px !important; }
.create_shipment_list_body input.mat-mdc-input-element { margin-top: 0; background: #fff !important; padding: 8.5px 10px !important; font-size: 12px !important; }

.before_login_user .wapper_body_rb { height: calc(100vh - 60px); }

/* messages scroller */
.v_scrollBar_messages { overflow-y: auto; overflow-x: hidden; height: calc(65vh - 0px);}
/* .v_scrollBar_messages::-webkit-scrollbar { width: 2px; height: 4px; } */
.v_scrollBar_messages::-webkit-scrollbar { width: 2px; border-radius: 10px; height: 3px; }
.v_scrollBar_messages::-webkit-scrollbar-thumb { background: #d9d9d9; border-radius: 10px; }

/* #massages_search .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex { min-width: 270px; } */
 @media screen and ( min-width: 1320px ) and (max-width: 1420px) {

  .v_scrollBar_messages .text-truncate { max-width: 100px;} 
  /* #massages_search .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex { min-width: 225px; } */
}

/* notification bell popup css */
#bell_notification_dropdown .mat-mdc-tab-label-active { background: none; color: #333 !important; border-bottom: 2px solid #3787e6;}
#bell_notification_dropdown .mat-mdc-tab-label { border: none; background: none !important; }
#bell_notification_dropdown .mat-mdc-tab-label-active .mat-mdc-tab-label-content { color: #000 !important; }
#bell_notification_dropdown .mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar, .mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-ink-bar { background-color: #3787e6; }



.num_country_code.ic_dropdown.edit_location_inputes input.mat-mdc-input-element { margin-top: -4px ; border-radius: 0 5px 5px 0px !important; }

.driver_number { position: relative;
  top: 1px;}

  .location_serch_tag .src_filters { height: 30px;}
  .driver_list_body .search_action .mat-mdc-icon { width: 17px; height: 17px; } 
  .driver_list_body .driver_search_input .mat-mdc-input-element {font-size: 12px; } 

.user_profile_rating .rating_stars { justify-content: center; }

/* shipment load cards css */
.pic_location_name span { max-width: calc(100% - 30px) !important; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; display: inline-block;}
.drop_lacation span { max-width: calc(100% - 30px) !important; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; display: inline-block;}



/* common css for header tabs */
// .mat-mdc-tab-labels { height: 32px;}
app-user-popup .mat-mdc-ink-bar { display: none; }


/* desput container */
.desput_container .custom_input { border: none !important;  font-size: 14px !important; }
/* shipment_creat_by_me */
.shipment_creat_by_me .custom_input { font-size: 14px !important;}
.shipment_creat_by_me .border-none { border: none !important; }


/* lane_view_details */
.lane_view_details app-lane-detail .cus_matTab { padding: 0px 25px; }
.lane_view_details .mat-mdc-tab-header { padding: 0 25px !important; }
.lane_view_details .detail_card_con   { margin: 30px 25px !important; }
.lane_view_details .user_pro_banner  { margin-top: 30px !important; margin-left: 25px !important; margin-right: 25px !important; }



/* for spinner loader */
.spinner-border { width: 15px; height: 15px; border-width: 2px; }


.progress_input_box .custum_input_css .mat-mdc-form-field-subscript-wrapper {
  margin-top: 16px;
} 


.shipment_detailPage app-load-tab #list-tab { margin-bottom: 25px;}

.lane_view_details .filter_label { margin-left: 30px !important; }
.creat_loads_con .filter_label {margin-left: 30px !important; }
.active_lanes_con .border-none { border: none !important;}


/* ratting dropdown css */
.ratting_dropdown .accordion-body {position: absolute; left: 46px; top: 41px; background: #fff; padding: 10px; border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; transition: .5s; }

@media (max-width:1300px) {

  .user_pro_banner .price_p { font-size: 16px; line-height: 20px; font-weight: 600; }
  
}

.left_side_panel .mat-accordion .mat-expansion-panel-body { padding: 0px;}
.left_side_panel .mat-accordion .mat-expansion-panel-header-description, .mat-expansion-indicator::after  { color: #fff;}
// left_side_panel .mat-expansion-panel-header { background-color: #3787e6; }

 mat-form-field { position: relative; }
.ic_dropdown .mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix { padding-top: 0px; padding-bottom: 0px; }
// .sub_tabs .mdc-tab { border-bottom: 2px solid #000 !important; background: #fff !important; color: #000 !important; border-radius: 0px !important; }
// .sub_tabs .mdc-tab__content .mdc-tab__text-label { color: #333 !important; }
// .sub_tabs .mdc-tab--active .mdc-tab__content .mdc-tab__text-label { color: #333 !important; }

.cus_matTab .mdc-tab-indicator { display: none;}
.cus_matTab .mdc-tab { background: #ffff; color: #2c2c2c; border-radius: 40px; height: 32px !important; margin-right: 20px; border: 1px solid #d9d9d9; }
.cus_matTab .mdc-tab:hover { background-color: #3787e6; }
.cus_matTab .mdc-tab:hover .mdc-tab__content .mdc-tab__text-label { color: #fff; }
.cus_matTab .mdc-tab .mdc-tab__content .mdc-tab__text-label { color: #333; font-weight: 400; }
.cus_matTab .mdc-tab--active { background: #3787e6; border: 1px solid #3787e6; }
.cus_matTab .mdc-tab--active .mdc-tab__content .mdc-tab__text-label { color: #fff !important; }
.mat-mdc-form-field-infix { background-color: #fff !important; }
.custom_matSuffix_icon .mat-mdc-form-field-flex { position: relative;}
.custom_matSuffix_icon .mat-mdc-form-field-icon-suffix { position: absolute; right: 0px; top: 16px; } 
.ic_password .mat-mdc-form-field-flex { position: relative;}
.ic_password .mat-mdc-form-field-icon-suffix { position: absolute; right: 0px; top: 16px; } 
.ic_password .mdc-icon-button { background-color: #f6f6f6 !important; height: 40px !important;  } 
.ic_password .mdc-icon-button:hover { background-color: #f6f6f6 !important; height: 40px !important;  } 


.mat-mdc-form-field-flex { background: #fff !important; }
.network_src .mat-mdc-form-field-icon-prefix { padding: 0 4px 0 10px; }
.num_country_code .frm_cunty_code { width: 80px; } 

.recurring_lanes_filter .mat-mdc-form-field-infix { padding-top: 0px !important; padding-bottom: 0px !important; }
.recurring_lanes_filter .custom_input { height: 37px !important;}


.unic_search .mat-mdc-form-field-icon-prefix { position: absolute; z-index: 2; }
.src_cancel_icons .mat-mdc-form-field-icon-prefix { position: absolute; z-index: 2; }
.unic_search .mdc-text-field__input { padding: 0 25px; }
.src_cancel_icons .mdc-text-field__input { padding: 0 30px; }


.mat-mdc-option { font-size: 12px; line-height: 16px; }
.mat-mdc-select-arrow-wrapper { height: 17px !important;}


.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #3787e6 !important;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #3787e6 !important;
}

.mdc-radio__native-control:focus { --mdc-radio-selected-focus-icon-color: #3787e6; --mdc-radio-selected-hover-icon-color: #3787e6; --mdc-radio-selected-icon-color: #3787e6; --mdc-radio-selected-pressed-icon-color: #37787e; --mat-radio-checked-ripple-color: #3787e6 important; }
.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #3787e6 !important;
}



// for popup padding
.mdc-dialog__container .mat-mdc-dialog-surface { padding: 24px !important;}