@font-face { font-family:Inter; font-weight:300; font-display:swap; font-style:normal; src:url(src/assets/fonts/inter/inter_3.ttf) format('truetype') }
@font-face { font-family:Inter; font-weight:400; font-display:swap; font-style:normal; src:url(src/assets/fonts/inter/inter_4.ttf) format('truetype') }
@font-face { font-family:Inter; font-weight:500; font-display:swap; font-style:normal; src:url(src/assets/fonts/inter/inter_5.ttf) format('truetype') }
@font-face { font-family:Inter; font-weight:600; font-display:swap; font-style:normal; src:url(src/assets/fonts/inter/inter_6.ttf) format('truetype') }
@font-face { font-family:Inter; font-weight:700; font-display:swap; font-style:normal; src:url(src/assets/fonts/inter/inter_7.ttf) format('truetype') }
@font-face { font-family:Inter; font-weight:800; font-display:swap; font-style:normal; src:url(src/assets/fonts/inter/inter_8.ttf) format('truetype') }
@font-face { font-family:Inter; font-weight:900; font-display:swap; font-style:normal; src:url(src/assets/fonts/inter/inter_9.ttf) format('truetype') }

@font-face { font-family:'Material Icons'; font-style:normal; font-weight:400; src:url(src/assets/fonts/mat_icons.woff2) format('woff2') }
.material-icons { font-family:'Material Icons'; font-weight:400; font-style:normal; font-size:24px; line-height:1; letter-spacing:normal; text-transform:none; display:inline-block; white-space:nowrap; word-wrap:normal; direction:ltr; -webkit-font-feature-settings:'liga'; -webkit-font-smoothing:antialiased }

@font-face { font-family: "Cedarville Cursive";  font-style: normal; font-weight: 400; font-display: swap; src: url(src/assets/fonts/signature/cedarville_cursive.woff2) format("woff2"); unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD }
@font-face { font-family: Dancing Script; font-style: normal; font-weight: 400; font-display: swap; src: url(src/assets/fonts/signature/dancing_script.woff2) format("woff2"); unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD }
@font-face { font-family: Great Vibes; font-style: normal; font-weight: 400; font-display: swap; src: url(src/assets/fonts/signature/great_vibes.woff2) format("woff2"); unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD }
@font-face { font-family: Sacramento; font-style: normal; font-weight: 400; font-display: swap; src: url(src/assets/fonts/signature/sacramento.woff2) format("woff2"); unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD }
@font-face { font-family: Tangerine; font-style: normal; font-weight: 400; font-display: swap; src: url(src/assets/fonts/signature/tangerine.woff2) format("woff2"); unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD }
@font-face { font-family: Yellowtail; font-style: normal; font-weight: 400; font-display: swap; src: url(src/assets/fonts/signature/yellowtail.woff2) format("woff2"); unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD }

body { overflow: hidden; }

* { word-break: break-word; font-family: 'Inter'; }